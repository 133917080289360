import ko, { unwrap } from "knockout"

Format = {}
Format.kilobytes = (kilobytes) ->
   assertArgs(arguments, Number)
   size = kilobytes
   units = ["KB", "MB", "GB", "TB"]
   cur = 0
   while size > 1000 and cur < units.length - 1
      size /= 1000
      cur++
   return "#{size.toFixed(1).replace(".0", "")} #{units[cur]}"

Format.prettyCamel = (input, options) ->

   isAcronym = (value) ->
      return value.length > 1 && value.toUpperCase() == value

   lowerCaseWord = (value) ->
      return if isAcronym(value) then value else value[0].toLowerCase() + value.substring(1)

   upperCaseWord = (value) ->
      return if isAcronym(value) then value else value[0].toUpperCase() + value.substring(1)

   throw new Error("Input must be specified.") unless input?

   options = options || {}
   words = input.match(/[A-Z\d]+(?![a-z])|[a-zA-Z][^A-Z\d\-_.]*/g)

   if words?
      for word in words
         continue if word.match(/^[A-Z\d][A-Z\d]+$/)
         if options["case"] == "lower"
            word = lowerCaseWord(word)
         else
            word = upperCaseWord(word)
      return words.join(" ")

   return input

Format.toUnderscore = (value) ->
   return value.replace(/([A-Z])/g, "_$1").toLowerCase()

Format.formatNumber = (value) ->
   return '' unless value?
   value = String(value)
   validChars = value.replace(/\s/g,  '')
   return '' unless validChars.length > 0
   chunks = value.split(".")
   val = "#{chunks[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")}"
   val += ".#{chunks[1]}" if chunks.length > 1
   return val      

Format.formatCurrency = (value) ->
   return "$#{Format.formatNumber(value)}"

Format.underscoreToTitle = (input) ->
   chunks = input.toLowerCase().split("_")
   capitalizedChunks = []
   for chunk in chunks
      capitalizedChunks.push(chunk.charAt(0).toUpperCase() + chunk.slice(1))
   return capitalizedChunks.join(" ")

Format.capitalize = (text) ->
   return text.charAt(0).toUpperCase() + text.slice(1)

Format.keyableSort = (array, key) ->
   assertArgs(arguments, Array, optional(String))
   if key?
      return array.sort (a, b) ->
         return String(unwrap(a[key])).toLowerCase().localeCompare(String(unwrap(b[key])).toLowerCase())
   else
      return array.sort (a, b) ->
         return String(a).toLowerCase().localeCompare(String(b).toLowerCase())

# TODO: Move to color util.
Format.hexToRgb = (hex) ->
    # Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
   shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
   hex = hex.replace shorthandRegex, (m, r, g, b) ->
      return r + r + g + g + b + b

   result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
   if result?
      return  {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
   else
      return null

# TODO: Move to color util.
Format.rgbToHex = (r, g, b) ->
   componentToHex = (val) ->
      hex = val.toString(16);
      return if hex.length == 1 then "0#{hex}" else hex

   return "##{componentToHex(r)}#{componentToHex(g)}#{componentToHex(b)}"

Format.camelCaseObjectToSnakeCase = (source) ->
   results = {}

   processComplexVal = (processingVal) ->
      if processingVal instanceof Array
         valArray = []
         for val in processingVal
            valArray.push(processComplexVal(ko.unwrap(val)))
         return valArray
      else if processingVal instanceof Object and !(processingVal instanceof Function)
         valObject = {}
         for key, val of processingVal
            valObject[Format.prettyCamel(key).replace(/\s/g, "_").toLowerCase()] = processComplexVal(ko.unwrap(val))
         return valObject
      return processingVal

   for key of source
      formattedKey = Format.prettyCamel(key).replace(/\s/g, "_").toLowerCase()
      resultingVal = processComplexVal(ko.unwrap(source[key]))
      results[formattedKey] = ko.unwrap(resultingVal)

   return results

Format.snakeCaseObjectToCamelCase = (source) ->
   toCamel = (item) -> 
      return item.replace /([-_][a-z])/ig, (chunk) ->
         return chunk.toUpperCase().replace('_', '')

   keysToCamel = (o) ->
      if (o == Object(o) && !Array.isArray(o) && typeof o != 'function')
         n = {}

         Object.keys(o)
            .forEach (k) ->
               n[toCamel(k)] = keysToCamel(o[k])

         return n;
      
      else if (Array.isArray(o))
         return o.map (i) ->
            return keysToCamel(i)
      
      else
         return o
   

   return keysToCamel(source)

export Format = Format