import { Store } from "@/stores/store"
import ko from "knockout"
import { DateUtils } from "@/lib/utils/date"

### Modals ###
import { ValueSet } from "@/models/value-set"

export class DefaultStore extends Store
   constructor: ->
      super()
      @vault = {
         "groups-options": null
      }
      @selectedDateFormat = ko.observable(DefaultStore.DateFormat.MM_DD_YYYY)
      @selectedDateFormatSeparator = ko.pureComputed =>
         # Should use a strategy lookup pattern if this supports 1 to many separators
         hasSlashes = @selectedDateFormat().indexOf('/') != -1
         return  if hasSlashes then "/" else "-"

   getLanguageOptions: (callback) ->
      assertArgs(arguments, Function)
      callback null, DefaultStore.Data.LANGUAGE_OPTIONS.map (option) ->
         return new ValueSet(option)

   getDefaultLanguageOptions: () ->
      return [...DefaultStore.Data.LANGUAGE_OPTIONS]

   getResourceColorStrings: (callback) ->
      callback(null, DefaultStore.ResourceColors)

   getDefaultResourceColorValues: () ->
      return [ ...DefaultStore.ResourceColors, ]

   getDateFormat: ->
      return @selectedDateFormat()
   
   getCompanyFormattedReportHeader: ->
      return DateUtils.formatDate(new Date(), defaultStore.getDateFormat(), {
         dayFormat: DateUtils.DayFormat.TWO_DIGIT,
         monthFormat: DateUtils.MonthFormat.ABBREV,
         yearFormat: DateUtils.YearFormat.FULL
      })

   setDateFormat: (format) ->
      @selectedDateFormat(format)

   getTimeOptions: (startingValue) ->
      assertArgs(arguments, optional(Number))
      unless startingValue?
         return DefaultStore.Data.TIME_OPTIONS.map (option) ->
            new ValueSet(option)

      cutoffTimes = []
      timeOptions = []
      for option in DefaultStore.Data.TIME_OPTIONS
         if option.value <= startingValue
            cutoffTimes.push(new ValueSet(option))
         else
            timeOptions.push(new ValueSet(option))
      return timeOptions.concat(cutoffTimes)

   formatTime: (timeValue) ->
      assertArgs(arguments, Number)
      for option in DefaultStore.Data.TIME_OPTIONS
         return option.name if option.value == timeValue

   getTimeOffReasons: ->
      return DefaultStore.Data.TIME_OFF_REASONS.map (item) ->
         return new ValueSet(item)

DefaultStore.Data = {
   TIME_OFF_REASONS: [
      # TODO: Fix value [bereave*r*ment -> bereave**ment].
      {
         name: "Bereavement"
         value: "bereaverment"
      },
      {
         name: "Family"
         value: "family"
      },
      {
         name: "Jury Duty"
         value: "jury duty"
      },
      {
         name: "Lack of Work"
         value: "lack of work"
      },
      {
         name: "Medical"
         value: "medical"
      },
      {
         name: "Military"
         value: "military"
      },
      {
         name: "Personal"
         value: "personal"
      },
      {
         name: "School"
         value: "school"
      },
      {
         name: "Sick"
         value: "sick"
      },
      {
         name: "Vacation"
         value: "vacation"
      },
      {
         name: "Other"
         value: "other"
      }
   ]
   LANGUAGE_OPTIONS: [
      {
         name: "English"
         value: "english"
      },{
         name: "Spanish"
         value: "spanish"
      },{
         name: "French"
         value: "french"
      },{
         name: "German"
         value: "german"
      },{
         name: "Italian"
         value: "italian"
      },{
         name: "Arabic"
         value: "arabic"
      },{
         name: "Russian"
         value: "russian"
      },{
         name: "Other"
         value: "other"
      }
   ],
   TIME_OPTIONS: [
         {name:"12:00 am", value: 0}
         {name:"12:15 am", value: 0.25}
         {name:"12:30 am", value: 0.5}
         {name:"12:45 am", value: 0.75}
         {name:"1:00 am", value: 1}
         {name:"1:15 am", value: 1.25}
         {name:"1:30 am", value: 1.5}
         {name:"1:45 am", value: 1.75}
         {name:"2:00 am", value: 2}
         {name:"2:15 am", value: 2.25}
         {name:"2:30 am", value: 2.5}
         {name:"2:45 am", value: 2.75}
         {name:"3:00 am", value: 3}
         {name:"3:15 am", value: 3.25}
         {name:"3:30 am", value: 3.5}
         {name:"3:45 am", value: 3.75}
         {name:"4:00 am", value: 4}
         {name:"4:15 am", value: 4.25}
         {name:"4:30 am", value: 4.5}
         {name:"4:45 am", value: 4.75}
         {name:"5:00 am", value: 5}
         {name:"5:15 am", value: 5.25}
         {name:"5:30 am", value: 5.5}
         {name:"5:45 am", value: 5.75}
         {name:"6:00 am", value: 6}
         {name:"6:15 am", value: 6.25}
         {name:"6:30 am", value: 6.5}
         {name:"6:45 am", value: 6.75}
         {name:"7:00 am", value: 7}
         {name:"7:15 am", value: 7.25}
         {name:"7:30 am", value: 7.5}
         {name:"7:45 am", value: 7.75}
         {name:"8:00 am", value: 8}
         {name:"8:15 am", value: 8.25}
         {name:"8:30 am", value: 8.5}
         {name:"8:45 am", value: 8.75}
         {name:"9:00 am", value: 9}
         {name:"9:15 am", value: 9.25}
         {name:"9:30 am", value: 9.5}
         {name:"9:45 am", value: 9.75}
         {name:"10:00 am", value: 10}
         {name:"10:15 am", value: 10.25}
         {name:"10:30 am", value: 10.5}
         {name:"10:45 am", value: 10.75}
         {name:"11:00 am", value: 11}
         {name:"11:15 am", value: 11.25}
         {name:"11:30 am", value: 11.5}
         {name:"11:45 am", value: 11.75}
         {name:"12:00 pm", value: 12}
         {name:"12:15 pm", value: 12.25}
         {name:"12:30 pm", value: 12.5}
         {name:"12:45 pm", value: 12.75}
         {name:"1:00 pm", value: 13}
         {name:"1:15 pm", value: 13.25}
         {name:"1:30 pm", value: 13.5}
         {name:"1:45 pm", value: 13.75}
         {name:"2:00 pm", value: 14}
         {name:"2:15 pm", value: 14.25}
         {name:"2:30 pm", value: 14.5}
         {name:"2:45 pm", value: 14.75}
         {name:"3:00 pm", value: 15}
         {name:"3:15 pm", value: 15.25}
         {name:"3:30 pm", value: 15.5}
         {name:"3:45 pm", value: 15.75}
         {name:"4:00 pm", value: 16}
         {name:"4:15 pm", value: 16.25}
         {name:"4:30 pm", value: 16.5}
         {name:"4:45 pm", value: 16.75}
         {name:"5:00 pm", value: 17}
         {name:"5:15 pm", value: 17.25}
         {name:"5:30 pm", value: 17.5}
         {name:"5:45 pm", value: 17.75}
         {name:"6:00 pm", value: 18}
         {name:"6:15 pm", value: 18.25}
         {name:"6:30 pm", value: 18.5}
         {name:"6:45 pm", value: 18.75}
         {name:"7:00 pm", value: 19}
         {name:"7:15 pm", value: 19.25}
         {name:"7:30 pm", value: 19.5}
         {name:"7:45 pm", value: 19.75}
         {name:"8:00 pm", value: 20}
         {name:"8:15 pm", value: 20.25}
         {name:"8:30 pm", value: 20.5}
         {name:"8:45 pm", value: 20.75}
         {name:"9:00 pm", value: 21}
         {name:"9:15 pm", value: 21.25}
         {name:"9:30 pm", value: 21.5}
         {name:"9:45 pm", value: 21.75}
         {name:"10:00 pm", value: 22}
         {name:"10:15 pm", value: 22.25}
         {name:"10:30 pm", value: 22.5}
         {name:"10:45 pm", value: 22.75}
         {name:"11:00 pm", value: 23}
         {name:"11:15 pm", value: 23.25}
         {name:"11:30 pm", value: 23.5}
         {name:"11:45 pm", value: 23.75}
         ]
}

DefaultStore.DateFormat = {
   MM_DD_YYYY: "MM/DD/YYYY"
   DD_MM_YYYY: "DD/MM/YYYY"
   YYYY_MM_DD: "YYYY/MM/DD"
   YYYY_DD_MM: "YYYY/DD/MM"
   DD_MM_YYYY_DASHED: "DD-MM-YYYY"
   MM_DD_YYYY_DASHED: "MM-DD-YYYY"
   YYYY_MM_DD_DASHED: "YYYY-MM-DD"
}

DefaultStore.ResourceColors = [
   "#FF0537", "#B51600", "#FF621C", "#FF9831", "#FFCF00", "#FFFB23", "#CDFF2F"
   "#8AF33F", "#50BF00", "#128F00", "#34FFA9", "#63FDFF", "#6FD9FF", "#53A9FF"
   "#4D70F4", "#D35EFF", "#9C68FF", "#744BFF", "#FF54BE", "#FD94FF"
]

DefaultStore.TagColors = [
   "#FFC9B7", "#FFE2AD", "#FFFFC9", "#E8FFCE", "#A9FFCE"
   "#C2FFFE", "#D6E8FF", "#EFDCFF", "#DAD3FF", "#FFE8F5"
]

DefaultStore.BrandingColors = [
   "#FF0000", "#00FF00", "#0000FF"
]

export defaultStore = new  DefaultStore()