import { ProcoreStore } from "@/stores/procore-store.core";
import { SessionStore } from "@/stores/session-store.core";
import { requestContext } from "@/stores/common/request-context";
import renderError from "@/react/render-error";
import LaunchDarklyBrowser from "@laborchart-modules/launch-darkly-browser";
import type { SessionPersonAuthenticated } from "@laborchart-modules/lc-core-api";
import { Person } from "@/models/person";

export enum AuthManagerError {
   INVALID_CREDENTIALS = "Invalid credentials.",
   RESTRICTED_GROUP_ACCESS = "User does not have access to group.",
   GROUP_OPTIONS_FAILED = "Failed to get the group options.",
}

export class AuthManager2 {
   static async attemptToRenewSession({
      authingWithProcore = false,
   }: {
      authingWithProcore?: boolean;
   } = {}): Promise<{ user: SessionPersonAuthenticated }> {
      try {
         const result = await SessionStore.renewSession(authingWithProcore).payload;
         const { session, wfp_access_token, wfp_refresh_token } = result.data;

         const user = session.authenticated_person;

         // Anything past here indicates auth success
         if (user == null) {
            throw new Error(AuthManagerError.INVALID_CREDENTIALS);
         }

         if (wfp_access_token) {
            localStorage.setItem("wfpAccessToken", wfp_access_token);
         }

         if (wfp_refresh_token) {
            localStorage.setItem("wfpRefreshToken", wfp_refresh_token);
         }

         // Check the URL and session storage for the helix header
         // If the query param is passed and header is not set,
         // Persist the session storage value to maintain helix experience as the user navigates around
         // the entire procore platform. The value will be cleared when the tab is closed.
         const urlParams = new URLSearchParams(window.location.search);
         const helix_param = urlParams.get("helix");
         const helix_header = sessionStorage.getItem("helix-header");
         const helix_enabled = helix_header == "true" || helix_param == "true";

         if (helix_enabled && !helix_header) {
            sessionStorage.setItem("helix-header", "true");
         }

         await Promise.all([
            LaunchDarklyBrowser.identify({
               kind: "multi",
               user: { kind: "user", key: user.email, helix: helix_enabled },
               email: { kind: "email", key: user.email },
               company_id: { kind: "company_id", key: user.company_id },
            }),
         ]);

         return { user };
      } catch (error) {
         if (requestContext.usingProcoreHostApp) {
            if (requestContext.authResult.error) {
               renderError({ ...requestContext.authResult });
            } else {
               ProcoreStore.loginWithHostApp();
            }
         } else {
            this.navigateToSignIn(true);
         }
         throw new Error(AuthManagerError.INVALID_CREDENTIALS);
      }
   }

   // Transforms SessionPersonAuthenticated (respponse from core-api) to front-end Person model.
   static getPersonModel(authenticatedUser: SessionPersonAuthenticated): Person {
      const transformed = {
         ...authenticatedUser,
         first_name: authenticatedUser.name.first,
         last_name: authenticatedUser.name.last,
         can_recieve_sms: authenticatedUser.can_receive_sms,
         can_recieve_email: authenticatedUser.can_receive_email,
         can_recieve_mobile: authenticatedUser.can_receive_mobile,
         address_1: authenticatedUser.address_1 ?? null,
         address_2: authenticatedUser.address_2 ?? null,
         state_province: authenticatedUser.state_province ?? null,
         city_town: authenticatedUser.city_town ?? null,
         country: authenticatedUser.country ?? null,
         phone: authenticatedUser.phone ?? null,
         email: authenticatedUser.email ?? null,
         hourly_wage: authenticatedUser.hourly_wage ?? null,
         group_ids: authenticatedUser.groups.map((g) => g.id),
         access_group_ids: authenticatedUser.company.using_typed_groups
            ? authenticatedUser.groups.map((g) => g.id)
            : null,
         baggage: {
            group_options: authenticatedUser.groups.map((g) => ({ id: g.id, name: g.name })),
            company_modules: {
               custom_fields_module: authenticatedUser.company.custom_fields_module,
               qr_code_module: authenticatedUser.company.qr_code_module,
               people_qr_codes: authenticatedUser.company.qr_code_config?.people != null,
               projects_qr_codes: authenticatedUser.company.qr_code_config?.projects != null,
               using_tag_categories: authenticatedUser.company.tag_categories.length > 0,
            },
            sensitive_fields: {
               people_sensitive_fields: authenticatedUser.company.people_sensitive_fields,
               projects_sensitive_fields: authenticatedUser.company.projects_sensitive_fields,
            },
            company_name: authenticatedUser.company.name,
            using_typed_groups: authenticatedUser.company.using_typed_groups,
            date_format: authenticatedUser.company.date_format_preference,
            is_support: authenticatedUser.is_support,
         },
      };
      return new Person(transformed, true);
   }

   static navigateToSignIn(redirectBack: boolean = false): void {
      // Navigating to the Gatekeeper login page will cause the app to lose its state.
      // Maintain the existing location path.
      const query =
         redirectBack === true
            ? `?redirect=${encodeURIComponent(location.pathname + location.hash)}`
            : "";

      if (!requestContext.usingProcoreHostApp) {
         window.location.replace(`/auth/login${query}`);
      }
   }
}
