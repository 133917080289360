export interface Point {
   top: number;
   left: number;
}

export interface Size {
   height: number;
   width: number;
}

export interface Bounds extends Size, Point {}

export function intersects(a: Bounds, b: Bounds): boolean {
   return (
      a.left <= b.left + b.width &&
      b.left <= a.left + a.width &&
      a.top <= b.top + b.height &&
      b.top <= a.top + a.height
   );
}

export function contains(parent: Bounds, child: Bounds): boolean {
   return (
      parent.left <= child.left &&
      parent.left + parent.width >= child.left + child.width &&
      parent.top <= child.top &&
      parent.top + parent.height >= child.top + child.height
   );
}

export function isEqualSize(a: Size, b: Size): boolean {
   return a.height == b.height && a.width == b.width;
}

export function isEqualBounds(a: Bounds, b: Bounds): boolean {
   return isEqualSize(a, b) && a.top == b.top && a.left == b.left;
}

export function area(size: Size): number {
   return size.width * size.height;
}

/**
 * Returns bounds representing the intersection between two bounds or null if the bounds
 * do not intersect.
 */
export function findIntersection(a: Bounds, b: Bounds): Bounds | null {
   const top = Math.max(a.top, b.top);
   const left = Math.max(a.left, b.left);
   const bottom = Math.min(a.top + a.height, b.top + b.height);
   const right = Math.min(a.left + a.width, b.left + b.width);
   const height = bottom - top;
   const width = right - left;
   return height > 0 && width > 0 ? { top, left, width, height } : null;
}

/**
 * Returns the delta to fully contain the child within the parent. The delta is relative to
 * the parent. For example, a delta of { left: 10, top: -10 } means the parent would need to
 * be adjusted right 10 and up 10 to contain the child.
 */
export function findContainmentDelta(parent: Bounds, child: Bounds): { top: number; left: number } {
   return {
      top: findContainmentDeltaDimension(
         parent,
         child,
         (bounds) => bounds.top,
         (bounds) => bounds.height,
      ),
      left: findContainmentDeltaDimension(
         parent,
         child,
         (bounds) => bounds.left,
         (bounds) => bounds.width,
      ),
   };
}

function findContainmentDeltaDimension<T>(
   parent: T,
   child: T,
   coordinateFn: (data: T) => number,
   sizeFn: (data: T) => number,
) {
   const parentCoordinate = coordinateFn(parent);
   const parentSize = sizeFn(parent);
   const childCoordinate = coordinateFn(child);
   const childSize = sizeFn(child);

   if (childCoordinate < parentCoordinate) {
      return childCoordinate - parentCoordinate;
   } else if (childCoordinate + childSize > parentCoordinate + parentSize) {
      return childCoordinate + childSize - (parentCoordinate + parentSize);
   }
   return 0;
}

export enum AnchoredPosition {
   ABOVE_LEFT = "above-left",
   ABOVE_CENTER = "above-center",
   ABOVE_RIGHT = "above-right",
   BELOW_LEFT = "below-left",
   BELOW_CENTER = "below-center",
   BELOW_RIGHT = "below-right",
   LEFT_TOP = "left-top",
   LEFT_CENTER = "left-center",
   LEFT_BOTTOM = "left-bottom",
   RIGHT_TOP = "right-top",
   RIGHT_CENTER = "right-center",
   RIGHT_BOTTOM = "right-bottom",
}

const DEFAULT_ANCHOR_POSITION_PREFERENCE = [
   AnchoredPosition.BELOW_CENTER,
   AnchoredPosition.BELOW_LEFT,
   AnchoredPosition.BELOW_RIGHT,
   AnchoredPosition.ABOVE_CENTER,
   AnchoredPosition.ABOVE_LEFT,
   AnchoredPosition.ABOVE_RIGHT,
   AnchoredPosition.LEFT_CENTER,
   AnchoredPosition.LEFT_TOP,
   AnchoredPosition.LEFT_BOTTOM,
   AnchoredPosition.RIGHT_CENTER,
   AnchoredPosition.RIGHT_TOP,
   AnchoredPosition.RIGHT_BOTTOM,
];

/**
 * Finds the optimal anchored position based on the given viewport, anchored bounds, and size of
 * the anchored content. The preferences break ties if multiple anchor positions are fully visible.
 */
export function findAnchoredPosition({
   viewport,
   anchor,
   size,
   preferences = DEFAULT_ANCHOR_POSITION_PREFERENCE,
   paddingBetween = 0,
}: {
   viewport: Bounds;
   anchor: Bounds;
   size: Size;
   preferences?: AnchoredPosition[];
   paddingBetween?: number;
}): { position: AnchoredPosition; bounds: Bounds } {
   type PotentialBounds = {
      position: AnchoredPosition;
      bounds: Bounds;
      intersection: Bounds | null;
   };
   const potentialBounds = new Map<AnchoredPosition, PotentialBounds>();

   // Add any preferences that are not already included.
   const positions = preferences.concat(
      DEFAULT_ANCHOR_POSITION_PREFERENCE.filter((p) => preferences.indexOf(p) == -1),
   );

   // Find if any of the positions are satisfied. Return early if so.
   for (let i = 0; i < positions.length; i++) {
      const position = positions[i];
      if (potentialBounds.has(position)) continue;
      const bounds = findAnchoredBounds({ anchor, size, position, paddingBetween });
      if (contains(viewport, bounds)) {
         return { position, bounds };
      }
      const intersection = findIntersection(viewport, bounds);
      potentialBounds.set(position, { position, intersection, bounds });
   }

   // Find the position with the largest area or closest to the viewport.
   let largestArea: PotentialBounds = potentialBounds.get(positions[0])!;
   let smallestDelta: PotentialBounds = potentialBounds.get(positions[0])!;
   for (let i = 0; i < positions.length; i++) {
      const position = positions[i];
      const potential = potentialBounds.get(position)!;
      if (
         (largestArea.intersection == null && potential.intersection != null) ||
         (largestArea.intersection != null &&
            potential.intersection != null &&
            area(potential.intersection) > area(largestArea.intersection))
      ) {
         largestArea = potential;
      }

      const potentialDelta = findContainmentDelta(viewport, potential.bounds);
      const current = findContainmentDelta(viewport, smallestDelta.bounds);

      if (
         Math.abs(potentialDelta.left) + Math.abs(potentialDelta.top) <
         Math.abs(current.left) + Math.abs(current.top)
      ) {
         smallestDelta = potential;
      }
   }

   // Use largest area if there's any intersection at all, otherwise use the smallest
   // delta.
   const result = largestArea.intersection != null ? largestArea : smallestDelta;
   return { position: result.position, bounds: result.bounds };
}

/**
 * Returns the bounds of anchored content based on anchoring bounds (anchor) and a position
 * at which to anchor.
 */
export function findAnchoredBounds({
   anchor,
   size,
   position,
   paddingBetween = 0,
}: {
   anchor: Bounds;
   size: Size;
   position: AnchoredPosition;
   paddingBetween?: number;
}): Bounds {
   switch (position) {
      case AnchoredPosition.ABOVE_LEFT:
         return {
            top: anchor.top - size.height - paddingBetween,
            left: anchor.left,
            ...size,
         };
      case AnchoredPosition.ABOVE_CENTER:
         return {
            top: anchor.top - size.height - paddingBetween,
            left: anchor.left + anchor.width / 2 - size.width / 2,
            ...size,
         };
      case AnchoredPosition.ABOVE_RIGHT:
         return {
            top: anchor.top - size.height - paddingBetween,
            left: anchor.left + anchor.width - size.width,
            ...size,
         };
      case AnchoredPosition.BELOW_LEFT:
         return {
            top: anchor.top + anchor.height + paddingBetween,
            left: anchor.left,
            ...size,
         };
      case AnchoredPosition.BELOW_CENTER:
         return {
            top: anchor.top + anchor.height + paddingBetween,
            left: anchor.left + anchor.width / 2 - size.width / 2,
            ...size,
         };
      case AnchoredPosition.BELOW_RIGHT:
         return {
            top: anchor.top + anchor.height + paddingBetween,
            left: anchor.left + anchor.width - size.width,
            ...size,
         };
      case AnchoredPosition.LEFT_TOP:
         return {
            top: anchor.top,
            left: anchor.left - size.width - paddingBetween,
            ...size,
         };
      case AnchoredPosition.LEFT_CENTER:
         return {
            top: anchor.top + anchor.height / 2 - size.height / 2,
            left: anchor.left - size.width - paddingBetween,
            ...size,
         };
      case AnchoredPosition.LEFT_BOTTOM:
         return {
            top: anchor.top + anchor.width - size.height,
            left: anchor.left - size.width - paddingBetween,
            ...size,
         };
      case AnchoredPosition.RIGHT_TOP:
         return {
            top: anchor.top,
            left: anchor.left + anchor.width + paddingBetween,
            ...size,
         };
      case AnchoredPosition.RIGHT_CENTER:
         return {
            top: anchor.top + anchor.height / 2 - size.height / 2,
            left: anchor.left + anchor.width + paddingBetween,
            ...size,
         };
      case AnchoredPosition.RIGHT_BOTTOM:
         return {
            top: anchor.top + anchor.width - size.height,
            left: anchor.left + anchor.width + paddingBetween,
            ...size,
         };
   }
}

/**
 * This is basically a debugger function for bounds.
 * When called, it will draw a square on the specified bounds on the screen.
 */
export function createSquare({
   bounds,
   border,
   backgroundColor,
   opacity,
}: {
   bounds: Bounds;
   border?: string;
   backgroundColor?: string;
   opacity?: string;
}): HTMLElement {
   const rectangleElement = document.createElement("div");
   [
      rectangleElement.style.top,
      rectangleElement.style.left,
      rectangleElement.style.height,
      rectangleElement.style.width,
      rectangleElement.style.backgroundColor,
      rectangleElement.style.position,
      rectangleElement.style.zIndex,
      rectangleElement.style.opacity,
      rectangleElement.style.pointerEvents,
      rectangleElement.style.border,
   ] = [
      `${bounds.top}px`,
      `${bounds.left}px`,
      `${bounds.height}px`,
      `${bounds.width}px`,
      backgroundColor ?? "blue",
      "absolute",
      "10000",
      opacity ?? "0.05",
      "none",
      border ?? "",
   ];
   document.body.append(rectangleElement);
   return rectangleElement;
}
