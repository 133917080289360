import { Model } from "@/models/model"
import { Tag } from "@/models/tag"
import { Position } from "@/models/position"
import { Note } from "@/models/note"
import { Attachment } from "@/models/attachment"
import { PermissionLevel } from "@/models/permission-level"
import { NotificationProfile } from "@/models/notification-profile"

### Framework Includes ###
import ko from "knockout"

export class Person extends Model
   constructor: (data, disableTypes) ->
      assertArgs(arguments, optional(Object), optional(Boolean))
      data = {} unless data?
      super(data)
      disableTypes = false unless disableTypes?
      unless disableTypes
         # Required
         assertOfType(data.id, String)
         assertOfType(data.first_name, String)
         assertOfType(data.last_name, String)
         assertOfType(data.status, String)
         assertOfType(data.can_recieve_sms, Boolean)
         assertOfType(data.can_recieve_email, Boolean)
         assertOfType(data.can_recieve_mobile, Boolean)
         assertOfType(data.language, String)
         assertOfType(data.group_ids, arrayOf(String))
         # Nullable
         assertOfType(data.address_1, optional(nullable(String)))
         assertOfType(data.address_2, optional(nullable(String)))
         assertOfType(data.state_province, optional(nullable(String)))
         assertOfType(data.city_town, optional(nullable(String)))
         assertOfType(data.country, optional(nullable(String)))
         assertOfType(data.phone, optional(nullable(String)))
         assertOfType(data.email, optional(nullable(String)))
         assertOfType(data.hourly_wage, nullable(Number))
         # Optional
         assertOfType(data.associated_project_ids, optional(arrayOf(String)))
         assertOfType(data.employee_number, optional(String))
         assertOfType(data.emergency_contact_name, optional(String))
         assertOfType(data.emergency_contact_number, optional(String))
         assertOfType(data.emergency_contact_email, optional(String))
         assertOfType(data.emergency_contact_relation, optional(String))
         assertOfType(data.dob, optional(Number))
         assertOfType(data.is_male, optional(Boolean))
         assertOfType(data.hired_date, optional(Number))
         assertOfType(data.profile_pic_url, optional(String))
         assertOfType(data.position, optional(Object))
         assertOfType(data.tag_instances, optional(arrayOf(Object)))
         assertOfType(data.notes, optional(arrayOf(Object)))
         assertOfType(data.permission_level, optional(Object))
         assertOfType(data.signature, optional(String))
         assertOfType(data.custom_fields, optional(arrayOf(Object)))
         assertOfType(data.failed_login_count, optional(Number))
         assertOfType(data.procore_person_id, optional(String))


      ###------------------------------------
         Model ID
      ------------------------------------###
      @id = data.id

      ###------------------------------------
         Knockout Observables
      ------------------------------------###
      @firstName = ko.observable(data.first_name || data.name?.first || {})
      @lastName = ko.observable(data.last_name || data.name?.last || {})
      @status = ko.observable(data.status)
      @employeeNumber = ko.observable(data.employee_number)
      @isUser = ko.observable(data.is_user)
      @isAssignable = ko.observable(data.is_assignable)
      @invitePending = ko.observable(data.invite_pending)
      # @permission = ko.observable(data.permission)
      @companyId = ko.observable(data.company_id)
      @address1 = ko.observable(data.address_1)
      @address2 = ko.observable(data.address_2)
      @stateProvince = ko.observable(data.state_province)
      @cityTown = ko.observable(data.city_town)
      @zipcode = ko.observable(data.zipcode)
      @country = ko.observable(data.country)
      @phone = ko.observable(data.phone)
      @email = ko.observable(data.email)
      @canRecieveSms = ko.observable(data.can_recieve_sms)
      @canRecieveEmail = ko.observable(data.can_recieve_email)
      @canRecieveMobile = ko.observable(data.can_recieve_mobile)
      @emergencyContactName = ko.observable(data.emergency_contact_name)
      @emergencyContactNumber = ko.observable(data.emergency_contact_number)
      @emergencyContactEmail = ko.observable(data.emergency_contact_email)
      @emergencyContactRelation = ko.observable(data.emergency_contact_relation)
      @hourlyWage = ko.observable(data.hourly_wage)
      @dob = ko.observable(data.dob)
      @language = ko.observable(data.language)
      @isMale = ko.observable(data.is_male)
      @hiredDate = ko.observable(data.hired_date)
      @profilePicUrl = ko.observable(data.profile_pic_url)
      @signature = ko.observable(data.signature)
      @positionId = ko.observable(data.position_id)
      @failedLoginCount = ko.observable(data.failed_login_count)
      @procorePersonId = ko.observable(data.procore_person_id)
      # Convience method to prevent checks elsewhere. 
      # Calcualted in the mobileDevice array construction.
      @hasNotifiableDevices = ko.observable(false)

      if data.position?
         @position = ko.observable(new Position(data.position, disableTypes))
      else
         @position = ko.observable(null)
      @permissionLevel = if data.permission_level? then ko.observable(new PermissionLevel(data.permission_level, disableTypes)) else ko.observable(null)
      @notificationProfile = if data.notification_profile? then ko.observable(new NotificationProfile(data.notification_profile, disableTypes)) else ko.observable(null)
      
      if data.is_user
         @preferences = if data.preferences? then ko.observable(new UserPreferences(data.preferences, disableTypes)) else ko.observable(null)
         @unreadNotificationCount = if data.unread_notification_count? then ko.observable(data.unread_notification_count) else ko.observable(null)
      else
         @preferences = ko.observable(null)
         @unreadNotificationCount = ko.observable(0)

      if data.custom_fields?
         @customFields = ko.observableArray data.custom_fields.map (item) ->
            return {
               fieldId: item.field_id
               integrationName: item.integration_name
               value: item.value
               name: item.name
               type: item.type
            }
      else
         @customFields = null

      ###------------------------------------
         Knockout Observable Arrays
      ------------------------------------###
      @groupIds = ko.observableArray(data.group_ids)
      @assignableGroupIds = ko.observableArray(data.assignable_group_ids or [])
      @accessGroupIds = ko.observableArray(data.access_group_ids or [])
      @associatedProjectIds = ko.observableArray(data.associated_project_ids or [])

      @assignmentIds = ko.observableArray(data.assignment_ids)

      if data.notes?
         @notes = ko.observableArray data.notes.map (note) ->
            return new Note(note, disableTypes)
      else
         @notes = ko.observableArray()

      if data.tag_instances?
         @tagInstances = ko.observableArray data.tag_instances.map (tag) ->
            return new Tag(tag, disableTypes)
      else
         @tagInstances = ko.observableArray()

      if data.attachments?
         @attachments = ko.observableArray data.attachments.map (attachment) ->
            return new Attachment(attachment, disableTypes)
      else
         @attachments = ko.observableArray()

      if data.mobile_devices?
         @mobileDevices = ko.observableArray data.mobile_devices.map (device) =>
            if device.fcm_token?
               @hasNotifiableDevices(true)
            return new MobileDevice(device, disableTypes)
      else
         @mobileDevices = ko.observableArray()

      ###------------------------------------------------------
         Conditionals & Knockout Computed Observables
      ---------------------------------------------------------###
      @fullName = ko.pureComputed =>
         return @firstName() + " " + @lastName()

export class UserPreferences extends Model
   constructor: (data, disableTypes) ->
      assertArgs(arguments, optional(Object), optional(Boolean))
      data = {} unless data?
      super(data)
      disableTypes = false unless disableTypes?
      unless disableTypes
         # Required
         assertOfType(data.default_group_id, nullable(String))
         assertOfType(data.display_last_names_first, Boolean)

      ###------------------------------------
         Knockout Observables
      ------------------------------------###
      @defaultGroupId = ko.observable(data.default_group_id)
      @displayLastNamesFirst = ko.observable(data.display_last_names_first)
      @benchCardsSubtitle = ko.observable(data.bench_cards_subtitle)
      @boardCardsSubtitle = ko.observable(data.board_cards_subtitle)
      @requestPerDay = ko.observable(data.request_per_day)

class MobileDevice extends Model
   constructor: (data, disableTypes) ->
      assertArgs(arguments, optional(Object), optional(Boolean))
      data = {} unless data?
      super(data)
      disableTypes = false unless disableTypes?
      unless disableTypes
         # Nullable
         assertOfType(data.device_name, nullable(String))
         assertOfType(data.device_os, nullable(String))
         assertOfType(data.device_uuid, nullable(String))
         assertOfType(data.fcm_token, nullable(String))
         assertOfType(data.model, nullable(String))
         assertOfType(data.version, nullable(String))

      ###------------------------------------
         Knockout Observables
      ------------------------------------###
      @deviceName = ko.observable(data.device_name)
      @deviceOs = ko.observable(data.device_os)
      @deviceUuid = ko.observable(data.device_uuid)
      @deviceFcmToken = ko.observable(data.fcm_token)
      @model = ko.observable(data.model)
      @version = ko.observable(data.version)

Person.Status = {
   ACTIVE: "active"
   INACTIVE: "inactive"
   PENDING: "pending"
}

Person.Permission = {
   ADMIN: "admin"
   MANAGER: "manager"
   RESTRICTED_MANAGER: "restricted-manager"
   USER: "user"
   RESTRICTED_USER: "restricted-user"
   VIEWER: "viewer"
   RESTRICTED_VIEWER: "restricted-viewer"
}

Person.UserPreferences = UserPreferences
Person.MobileDevice = MobileDevice
