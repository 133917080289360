import { Model } from "@/models/model"
import { Attachment } from "@/models/attachment"

export class Note extends Model
   constructor: (data, disableTypes) ->
      assertArgs(arguments, optional(Object), optional(Boolean))
      data = {} unless data?
      super(data)
      disableTypes = false unless disableTypes?
      unless disableTypes
         # Required
         assertOfType(data.id, String)
         assertOfType(data.content, String)
         assertOfType(data.is_private, Boolean)
         # Optional
         assertOfType(data.attachments, optional(arrayOf(Object)))
         assertOfType(data.last_edited, optional(Number))

      ###------------------------------------
         Model ID
      ------------------------------------###
      @id = data.id

      ###------------------------------------
         Knockout Observables
      ------------------------------------###
      @author = ko.observable("#{data.author.first} #{data.author.last}")
      @authorId = ko.observable(data.author_id)
      @content = ko.observable(data.content)
      @lastEdited = ko.observable(data.last_edited)
      @isPrivate = ko.observable(data.is_private)

      ###------------------------------------
         Knockout Observable Arrays
      ------------------------------------###
      @attachments = ko.observableArray data.attachments?.map (attachment) ->
         return new Attachment(attachment, disableTypes)

