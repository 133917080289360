import type { PermissionLevel } from "@/models/permission-level";
import type { Person } from "@/models/person";
import { authManager } from "../managers/auth-manager";
import type { Computed } from "knockout";

export enum BUGSNAG_META_TAB {
   ASSIGNMENT = "assignment",
   PERMISSION = "user permission",
   PERSON = "person",
   PROJECT = "project",
   REQUEST = "request",
   USER_DATA = "user data",
}

export type BugsnagUserData = {
   user: {
      name: string;
      company_id: string;
      company_name: string;
      is_support: boolean;
      is_admin: boolean;
      group_ids: string[];
   };
   permission: BugsnagPermissionData;
};

export type BugsnagPermissionData =
   | Omit<PermissionLevel, "enabled_count" | "total_count" | "applied_people">
   | {
        NO_PERMISSION: "User has no permission";
     };

/*
This function takes in the active user and spits out the useful fields for debugging purposes in BugSnag.
ID and email are intentionally left out, as BugSnag grabs that information in a separate location.
For the params, always use the following:
   - user: authManager.authedUser()
   - activePermission: authManager.activePermission()
*/
export function buildUserData(
   user: Person,
   activePermission: Computed<PermissionLevel | null>,
): BugsnagUserData {
   return {
      user: {
         name: user.fullName(),
         company_id: user.companyId(),
         company_name: user.baggage().company_name,
         is_support: authManager.isSupportUser(),
         is_admin: authManager.isAdmin(),
         group_ids: user.groupIds(),
      },
      permission: buildPermissionData(activePermission),
   };
}

export function buildPermissionData(
   activePermission: Computed<PermissionLevel | null>,
): BugsnagPermissionData {
   const permission = activePermission();
   // This should never happen.
   if (permission == null) {
      return {
         NO_PERMISSION: "User has no permission",
      };
   }
   const { enabled_count, total_count, applied_people, ...snagPermission } = permission.allToJson();
   return snagPermission;
}
