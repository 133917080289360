import { Model } from "@/models/model"

### Framework Includes ###
import ko from "knockout"

export class PermissionLevel extends Model
   constructor: (data, disableTypes) ->
      assertArgs(arguments, optional(Object), optional(Boolean))
      data = {} unless data?
      super(data)
      disableTypes = false unless disableTypes?
      unless disableTypes
         # Required
         assertOfType(data.id, String)
         

      ###------------------------------------
         Model ID & Meta
      ------------------------------------###
      @id = data.id

      ###------------------------------------
         Knockout Observables
      ------------------------------------###
      @companyId = ko.observable(data.company_id)
      ### Properties ###
      @name = ko.observable(data.name)
      @isAdmin = ko.observable(data.is_admin)
      @visibleStatusIds = ko.observable(new Set(data.visible_status_ids))

      # People
      @viewPeople = ko.observable(data.view_people)
      @editPeoplePermissions = ko.observable(data.edit_people_permissions)
      @editPeopleDetails = ko.observable(data.edit_people_details)
      @viewPeopleSensitive = ko.observable(data.view_people_sensitive)
      @editPeopleSensitive = ko.observable(data.edit_people_sensitive)
      @viewPeopleTags = ko.observable(data.view_people_tags)
      @editPeopleTags = ko.observable(data.edit_people_tags)
      @viewPeopleAttachments = ko.observable(data.view_people_attachments)
      @editPeopleAttachments = ko.observable(data.edit_people_attachments)
      @viewPeopleTimeoff = ko.observable(data.view_people_timeoff)
      @editPeopleTimeoff = ko.observable(data.edit_people_timeoff)
      @viewPeopleNotes = ko.observable(data.view_people_notes)
      @editPeopleNotes = ko.observable(data.edit_people_notes)
      @viewPeopleActivity = ko.observable(data.view_people_activity)
      @deletePeople = ko.observable(data.delete_people)
      @createPeople = ko.observable(data.create_people)

      # Projects
      @viewProject = ko.observable(data.view_project)
      @editProjectDetails = ko.observable(data.edit_project_details)
      @viewProjectSensitive = ko.observable(data.view_project_sensitive)
      @editProjectSensitive = ko.observable(data.edit_project_sensitive)
      @viewProjectTags = ko.observable(data.view_project_tags)
      @editProjectTags = ko.observable(data.edit_project_tags)
      @viewProjectAttachments = ko.observable(data.view_project_attachments)
      @editProjectAttachments = ko.observable(data.edit_project_attachments)
      @editProjectCategories = ko.observable(data.edit_project_categories)
      @viewProjectRoles = ko.observable(data.view_project_roles)
      @editProjectRoles = ko.observable(data.edit_project_roles)
      @viewProjectWageOverrides = ko.observable(data.view_project_wage_overrides)
      @editProjectWageOverrides = ko.observable(data.edit_project_wage_overrides)
      @editProjectCustomAlerts = ko.observable(data.edit_project_custom_alerts)
      @viewProjectDefaultRecipients = ko.observable(data.view_project_default_recipients)
      @editProjectDefaultRecipients = ko.observable(data.edit_project_default_recipients)
      @viewProjectNotes = ko.observable(data.view_project_notes)
      @editProjectNotes = ko.observable(data.edit_project_notes)
      @viewProjectActivity = ko.observable(data.view_project_activity)
      @deleteProject = ko.observable(data.delete_project)
      @createProject = ko.observable(data.create_project)
      @viewUnassociatedProjects = ko.observable(data.view_unassociated_projects)

      # Requests
      @viewRequests = ko.observable(data.view_requests)
      @manageRequests = ko.observable(data.manage_requests)
      @manageOthersRequests = ko.observable(data.manage_others_requests)
      @viewRequestsNotes = ko.observable(data.view_requests_notes)

      # Assignments
      @viewAssignments = ko.observable(data.view_assignments)
      @manageAssignments = ko.observable(data.manage_assignments)

      # Statuses
      @canViewAllStatuses = ko.observable(data.can_view_all_statuses)

      # Reports & Exporting
      @allowExportingData = ko.observable(data.allow_exporting_data)

      # Page Specific
      @accessMapPage = ko.observable(data.access_map_page)
      @accessGanttPage = ko.observable(data.access_gantt_page)
      @accessTotalsPage = ko.observable(data.access_totals_page)

      # Messages
      @createMessages = ko.observable(data.create_messages)

      # Alerts
      @viewAlerts = ko.observable(data.view_alerts)
      @manageAlerts = ko.observable(data.manage_alerts)

      # Financial
      @viewProjectFinancials = ko.observable(data.view_project_financials)
      @viewPeopleFinancials = ko.observable(data.view_people_financials)

      # Settings
      @viewCompanySettings = ko.observable(data.view_company_settings)
      @manageCompanySettings = ko.observable(data.manage_company_settings)
      @viewPermissionSettings = ko.observable(data.view_permission_settings)
      @managePermissionSettings = ko.observable(data.manage_permission_settings)
      @viewGroupSettings = ko.observable(data.view_group_settings)
      @manageGroupSettings = ko.observable(data.manage_group_settings)
      @viewPositionSettings = ko.observable(data.view_position_settings)
      @managePositionSettings = ko.observable(data.manage_position_settings)
      @viewTagsSettings = ko.observable(data.view_tags_settings)
      @manageTagsSettings = ko.observable(data.manage_tags_settings)
      @viewStatusSettings = ko.observable(data.view_status_settings)
      @manageStatusSettings = ko.observable(data.manage_status_settings)
      @viewAlertsSettings = ko.observable(data.view_alerts_settings)
      @manageAlertsSettings = ko.observable(data.manage_alerts_settings)
      @viewCostingSettings = ko.observable(data.view_costing_settings)
      @manageCostingSettings = ko.observable(data.manage_costing_settings)
      @viewCustomFieldsSettings = ko.observable(data.view_custom_fields_settings)
      @manageCustomFieldsSettings = ko.observable(data.manage_custom_fields_settings)
      @viewQrCodesSettings = ko.observable(data.view_qr_codes_settings)
      @manageQrCodesSettings = ko.observable(data.manage_qr_codes_settings)
      @viewNotificationsSettings = ko.observable(data.view_notifications_settings)
      @manageNotificationsSettings = ko.observable(data.manage_notifications_settings)

      # Misc
      @canUnlockUsers = ko.observable(data.can_unlock_users)

      @enabledCount = ko.observable(0)
      @totalCount = ko.observable(Object.values(PermissionLevel.Action).length)
      processingCount = 0
      for key, val of @
         continue if key == "isAdmin"
         val = ko.unwrap(val)
         if typeof val == "boolean"
            processingCount++ if val

      @enabledCount(processingCount)

      @appliedPeople = ko.observableArray(data.applied_people)


PermissionLevel.Action = {
   # People
   VIEW_PEOPLE: "viewPeople"
   EDIT_PEOPLE_PERMISSIONS: "editPeoplePermissions"
   EDIT_PEOPLE_DETAILS: "editPeopleDetails"
   VIEW_PEOPLE_SENSITIVE: "viewPeopleSensitive"
   EDIT_PEOPLE_SENSITIVE: "editPeopleSensitive"
   VIEW_PEOPLE_TAGS: "viewPeopleTags"
   EDIT_PEOPLE_TAGS: "editPeopleTags"
   VIEW_PEOPLE_ATTACHMENTS: "viewPeopleAttachments"
   EDIT_PEOPLE_ATTACHMENTS: "editPeopleAttachments"
   VIEW_PEOPLE_TIMEOFF: "viewPeopleTimeoff"
   EDIT_PEOPLE_TIMEOFF: "editPeopleTimeoff"
   VIEW_PEOPLE_NOTES: "viewPeopleNotes"
   EDIT_PEOPLE_NOTES: "editPeopleNotes"
   VIEW_PEOPLE_ACTIVITY: "viewPeopleActivity"
   DELETE_PEOPLE: "deletePeople"
   CREATE_PEOPLE: "createPeople"

   # Projects
   VIEW_PROJECT: "viewProject"
   EDIT_PROJECT_DETAILS: "editProjectDetails"
   VIEW_PROJECT_SENSITIVE: "viewProjectSensitive"
   EDIT_PROJECT_SENSITIVE: "editProjectSensitive"
   VIEW_PROJECT_TAGS: "viewProjectTags"
   EDIT_PROJECT_TAGS: "editProjectTags"
   VIEW_PROJECT_ATTACHMENTS: "viewProjectAttachments"
   EDIT_PROJECT_ATTACHMENTS: "editProjectAttachments"
   EDIT_PROJECT_CATEGORIES: "editProjectCategories"
   VIEW_PROJECT_ROLES: "viewProjectRoles"
   EDIT_PROJECT_ROLES: "editProjectRoles"
   VIEW_PROJECT_WAGE_OVERRIDES: "viewProjectWageOverrides"
   EDIT_PROJECT_WAGE_OVERRIDES: "editProjectWageOverrides"
   EDIT_PROJECT_CUSTOM_ALERTS: "editProjectCustomAlerts"
   VIEW_PROJECT_DEFAULT_RECIPIENTS: "viewProjectDefaultRecipients"
   EDIT_PROJECT_DEFAULT_RECIPIENTS: "editProjectDefaultRecipients"
   VIEW_PROJECT_NOTES: "viewProjectNotes"
   EDIT_PROJECT_NOTES: "editProjectNotes"
   VIEW_PROJECT_ACTIVITY: "viewProjectActivity"
   DELETE_PROJECT: "deleteProject"
   CREATE_PROJECT: "createProject"
   VIEW_UNASSOCIATED_PROJECTS: "viewUnassociatedProjects"

   # Requests
   VIEW_REQUESTS: "viewRequests"
   MANAGE_REQUESTS: "manageRequests"
   MANAGE_OTHERS_REQUESTS: "manageOthersRequests"
   VIEW_REQUESTS_NOTES: "viewRequestsNotes"

   # Assignments
   VIEW_ASSIGNMENTS: "viewAssignments"
   MANAGE_ASSIGNMENTS: "manageAssignments"

   # Statuses
   CAN_VIEW_ALL_STATUSES: "canViewAllStatuses"

   # Reports & Exporting
   ALLOW_EXPORTING_DATA: "allowExportingData"

   # Page Specific
   ACCESS_MAP_PAGE: "accessMapPage"
   ACCESS_GANTT_PAGE: "accessGanttPage"
   ACCESS_TOTALS_PAGE: "accessTotalsPage"
   
   # Messages
   CREATE_MESSAGES: "createMessages"

   # Alerts
   VIEW_ALERTS: "viewAlerts"
   MANAGE_ALERTS: "manageAlerts"

   # Financial
   VIEW_PROJECT_FINANCIALS: "viewProjectFinancials"
   VIEW_PEOPLE_FINANCIALS: "viewPeopleFinancials"

   # Settings
   VIEW_COMPANY_SETTINGS: "viewCompanySettings"
   MANAGE_COMPANY_SETTINGS: "manageCompanySettings"
   VIEW_PERMISSION_SETTINGS: "viewPermissionSettings"
   MANAGE_PERMISSION_SETTINGS: "managePermissionSettings"
   VIEW_GROUP_SETTINGS: "viewGroupSettings"
   MANAGE_GROUP_SETTINGS: "manageGroupSettings"
   VIEW_POSITION_SETTINGS: "viewPositionSettings"
   MANAGE_POSITION_SETTINGS: "managePositionSettings"
   VIEW_TAGS_SETTINGS: "viewTagsSettings"
   MANAGE_TAGS_SETTINGS: "manageTagsSettings"
   VIEW_STATUS_SETTINGS: "viewStatusSettings"
   MANAGE_STATUS_SETTINGS: "manageStatusSettings"
   VIEW_ALERTS_SETTINGS: "viewAlertsSettings"
   MANAGE_ALERTS_SETTINGS: "manageAlertsSettings"
   VIEW_COSTING_SETTINGS: "viewCostingSettings"
   MANAGE_COSTING_SETTINGS: "manageCostingSettings"
   VIEW_CUSTOM_FIELDS_SETTINGS: "viewCustomFieldsSettings"
   MANAGE_CUSTOM_FIELDS_SETTINGS: "manageCustomFieldsSettings"
   VIEW_QR_CODES_SETTINGS: "viewQrCodesSettings"
   MANAGE_QR_CODES_SETTINGS: "manageQrCodesSettings"
   VIEW_NOTIFICATIONS_SETTINGS: "viewNotificationsSettings"
   MANAGE_NOTIFICATIONS_SETTINGS: "manageNotificationsSettings"

   # Misc
   CAN_UNLOCK_USERS: "canUnlockUsers"
}
