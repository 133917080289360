import { DefaultStore } from "@/stores/default-store"
import { timeParse, timeFormat } from "d3"
DateUtils = {}

#region Token & Utility Function Defintion
MonthFormat = {
   ONE_DIGIT: 'M'
   TWO_DIGIT: 'MM'
   ABBREV: 'MMM'
   FULL: 'MMMM'
}

DayFormat = {
   ONE_DIGIT: 'D'
   TWO_DIGIT: 'DD'
}

YearFormat = {
   SHORT: 'YY'
   FULL: 'YYYY'
}

WeekDayFormat = {
   NONE: 'W'
   ABBREV: 'WWW'
   FULL: 'WWWW'
}

WorkDayAbbreviations = {
   0: "Su"
   1: "M"
   2: "Tu"
   3: "W"
   4: "Th"
   5: "F"
   6: "Sa"
}

DateUtils.getFormattedWorkDays = (workDaysObject) ->
   workDayString = []
   seekingActiveDay = true
   lastActiveKey = null
   for key in [0...7]
      # Are we looking for the start of a new day grouping?
      if seekingActiveDay
         if workDaysObject[key]
            lastActiveKey = key
            workDayString.push(WorkDayAbbreviations[key])
            seekingActiveDay = false
      # We already have at least one element in the day grouping
      # so we are looking for the next false
      else if !workDaysObject[key]
         # Ok to always subtract because Sunday can't be hit here.
         previousKey = key - 1
         # If previous and last active keys are equal set seekingActiveDay back to true
         if previousKey == lastActiveKey
            seekingActiveDay = true
         # Otherwise, we need to add the last element of the day
         # grouping and reset seekingActiveDay to true
         else
            workDayString[workDayString.length - 1] += "-#{WorkDayAbbreviations[key - 1]}"
            seekingActiveDay = true
      else if key == Object.entries(workDaysObject).length - 1
         # Will only hit this block if day is saturday and value is true
         workDayString[workDayString.length - 1] += "-#{WorkDayAbbreviations[key]}"

   return workDayString.join(", ")
          
# Common Configurations for reuse
DateUtils.LONG_FORM_OPTIONS = {
   monthFormat: MonthFormat.FULL
   dayFormat: DayFormat.ONE_DIGIT
   yearFormat: YearFormat.FULL
   weekdayFormat: WeekDayFormat.NONE
}

padZeroIfLessThanTen = (value) -> if value < 10 then '0' + String(value) else String(value)

#endregion Token & Utility Function Defintion

DateUtils.applyDateFormat = (dd, mm, yy, dateFormat, options = {}) ->
   { 
      yearFormat = YearFormat.FULL,
      monthFormat = MonthFormat.ONE_DIGIT,
      dayFormat = DayFormat.ONE_DIGIT,
      weekdayFormat = WeekDayFormat.NONE
   } = options

   yearValue = switch yearFormat
      when YearFormat.FULL then yy
      else String(yy).slice(2,4)

   monthValue = switch monthFormat
      when MonthFormat.TWO_DIGIT then padZeroIfLessThanTen(mm)
      when MonthFormat.ABBREV then DateUtils.getMonthAbbreviation(mm - 1)
      when MonthFormat.FULL then DateUtils.getMonth(mm - 1)
      else  mm

   dayValue = switch dayFormat
      when DayFormat.TWO_DIGIT then padZeroIfLessThanTen(dd)
      else dd

   if weekdayFormat and weekdayFormat != WeekDayFormat.NONE
      date = new Date(yy, mm - 1, dd)
      weekdayValue = switch weekdayFormat
         when WeekDayFormat.ABBREV then "#{DateUtils.getWeekDayAbbreviation(date)}, "
         when WeekDayFormat.FULL then "#{DateUtils.getWeekDay(date)}, "
   else
      weekdayValue = ""

   # If monthFormat represents a word instead of number, then return a long form day.
   if monthFormat == MonthFormat.ABBREV or monthFormat == MonthFormat.FULL
      return switch dateFormat
         when DefaultStore.DateFormat.DD_MM_YYYY, DefaultStore.DateFormat.DD_MM_YYYY_DASHED
         then "#{weekdayValue}#{dayValue} #{monthValue} #{yearValue}"
         when DefaultStore.DateFormat.MM_DD_YYYY, DefaultStore.DateFormat.MM_DD_YYYY_DASHED
         then "#{weekdayValue}#{monthValue} #{dayValue}, #{yearValue}"
         when DefaultStore.DateFormat.YYYY_MM_DD, DefaultStore.DateFormat.YYYY_MM_DD_DASHED
         then "#{weekdayValue}#{yearValue} #{monthValue} #{dayValue}"
         when DefaultStore.DateFormat.YYYY_DD_MM
         then "#{weekdayValue}#{yearValue}, #{dayValue} #{monthValue}"

   return switch dateFormat
      when DefaultStore.DateFormat.DD_MM_YYYY then "#{weekdayValue}#{dayValue}/#{monthValue}/#{yearValue}"
      when DefaultStore.DateFormat.MM_DD_YYYY then "#{weekdayValue}#{monthValue}/#{dayValue}/#{yearValue}"
      when DefaultStore.DateFormat.YYYY_MM_DD then "#{weekdayValue}#{yearValue}/#{monthValue}/#{dayValue}"
      when DefaultStore.DateFormat.YYYY_DD_MM then "#{weekdayValue}#{yearValue}/#{dayValue}/#{monthValue}"
      when DefaultStore.DateFormat.DD_MM_YYYY_DASHED then "#{weekdayValue}#{dayValue}-#{monthValue}-#{yearValue}"
      when DefaultStore.DateFormat.MM_DD_YYYY_DASHED then "#{weekdayValue}#{monthValue}-#{dayValue}-#{yearValue}"
      when DefaultStore.DateFormat.YYYY_MM_DD_DASHED then "#{weekdayValue}#{yearValue}-#{monthValue}-#{dayValue}"
      else "#{weekdayValue}#{dayValue}/#{monthValue}/#{yearValue}"

#region Date Section
DateUtils.formatDate = (date, dateFormat = DefaultStore.DateFormat.MM_DD_YYYY, options = {}) ->
   assertArgs(arguments, Date, optional(String), optional(Object))
   ### eslint-disable no-unused-vars ###
   {
      yearFormat = YearFormat.FULL,
      monthFormat = MonthFormat.TWO_DIGIT,
      dayFormat = DayFormat.TWO_DIGIT,
      weekdayFormat = WeekDayFormat.NONE
   } = options
   return DateUtils.applyDateFormat(
      date.getDate(),
      date.getMonth() + 1,
      date.getFullYear(),
      dateFormat,
      options
   )
   ### eslint-disable no-unused-vars ###

DateUtils.hasDayBeforeMonth = (dateFormat) ->
   return switch dateFormat
      when DefaultStore.DateFormat.DD_MM_YYYY
            , DefaultStore.DateFormat.YYYY_DD_MM
            , DefaultStore.DateFormat.DD_MM_YYYY_DASHED
      then true
      when DefaultStore.DateFormat.MM_DD_YYYY
            , DefaultStore.DateFormat.YYYY_MM_DD
            , DefaultStore.DateFormat.MM_DD_YYYY_DASHED
            , DefaultStore.DateFormat.YYYY_MM_DD_DASHED
      then false

# Includes month, date and two digit year
# TODO: Should be deleted as part of currently ongoing datetime work
DateUtils.getShortNumericDate = (date, dateFormat = DefaultStore.DateFormat.MM_DD_YYYY, options) ->
   return '' if !date or date == Infinity or isNaN(date)
   dateObject = if typeof date == 'number' then DateUtils.getAttachedDate(date) else date

   return DateUtils.applyDateFormat(
      dateObject.getDate(),
      dateObject.getMonth() + 1,
      dateObject.getFullYear(),
      dateFormat,
      options
   )

DateUtils.decrementDate = (date, step) ->
   assertArgs(arguments, Date, optional(Number))
   step = step or 1
   baseDate = new Date(date)
   return new Date(baseDate.setDate(baseDate.getDate() - step))

# Formatting of dates outside of date objects is mostly standardized to be yyyymmdd format
#   this method converts a number to a date object
DateUtils.getAttachedDate = (detachedDate) ->
   assertArgs(arguments, Number)
   detachedDate = String(detachedDate)
   return new Date(detachedDate.slice(0,4), detachedDate.slice(4,6), detachedDate.slice(6,8))

DateUtils.incrementDate = (date, step) ->
   assertArgs(arguments, Date, optional(Number))
   step = step or 1
   baseDate = new Date(date)
   return new Date(baseDate.setDate(baseDate.getDate() + step))

DateUtils.incrementDateMonths = (date, step) ->
   assertArgs(arguments, Date, optional(Number))
   step = step or 1
   baseDate = new Date(date)
   return new Date(baseDate.setMonth(baseDate.getMonth() + step))

#endregion Date Section

#region Detached Day Section
DateUtils.formatDetachedDay = (detachedDay, dateFormat = DefaultStore.DateFormat.MM_DD_YYYY, options = {}) ->
   {
      yearFormat = YearFormat.SHORT,
      monthFormat = MonthFormat.TWO_DIGIT,
      dayFormat = DayFormat.TWO_DIGIT,
      weekdayFormat = WeekDayFormat.NONE
   } = options
   return '' if !detachedDay or detachedDay == Infinity or isNaN(detachedDay)
   dateObject = if typeof detachedDay == 'number' then DateUtils.getAttachedDate(detachedDay) else detachedDay
   return DateUtils.applyDateFormat(
      dateObject.getDate(),
      dateObject.getMonth() + 1,
      dateObject.getFullYear(),
      dateFormat,
      options
   )

DateUtils.getDetachedDay = (date) ->
   assertArgs(arguments, Date)
   return (date.getFullYear() * 10000) + (date.getMonth() * 100) + (date.getDate())

DateUtils.getDetachedDayWorkday = (day) ->
   day = String(day)
   return new Date(day.slice(0,4), day.slice(4,6), day.slice(6,8)).getDay()

DateUtils.incrementDetachedDay = (day, step) ->
   assertArgs(arguments, Number, optional(Number))
   step = step or 1
   detachedDate = String(day)
   baseDate = new Date(detachedDate.slice(0,4), detachedDate.slice(4,6), detachedDate.slice(6,8))
   baseDate = new Date(baseDate.setDate(baseDate.getDate() + step))
   return (baseDate.getFullYear() * 10000) + (baseDate.getMonth() * 100) + (baseDate.getDate())

DateUtils.incrementDetachedDayMonths = (day, step) ->
   assertArgs(arguments, Number, optional(Number))
   step = step or 1
   detachedDate = String(day)
   baseDate = new Date(detachedDate.slice(0,4), detachedDate.slice(4,6), detachedDate.slice(6,8))
   steppedDate = new Date(baseDate.setMonth(baseDate.getMonth() + step))
   return (steppedDate.getFullYear() * 10000) + (steppedDate.getMonth() * 100) + (steppedDate.getDate())

DateUtils.decrementDetachedDay = (day, step) ->
   assertArgs(arguments, Number, optional(Number))
   step = step or 1
   detachedDate = String(day)
   baseDate = new Date(detachedDate.slice(0,4), detachedDate.slice(4,6), detachedDate.slice(6,8))
   baseDate = new Date(baseDate.setDate(baseDate.getDate() - step))
   return (baseDate.getFullYear() * 10000) + (baseDate.getMonth() * 100) + (baseDate.getDate())

DateUtils.getDaysBetweenDetachedDays = (start, end) ->
   assertArgs(arguments, Number, Number)
   first = DateUtils.getAttachedDate(start)
   second = DateUtils.getAttachedDate(end)
   return DateUtils.daysBetweenDates(first, second)

#endregion Detached Day Section

#region Generic Date or Number Section
DateUtils.getMonth = (date) ->
   assertArgs(arguments, [Date, Number])
   months = ["January", "February", "March", "April", "May", \
      "June", "July", "August", "September", "October", \
      "November", "December"]
   return months[date.getMonth()] if date instanceof Date
   return months[date]

DateUtils.getMonthAbbreviation = (date) ->
   assertArgs(arguments, [Date, Number])
   months = ["Jan", "Feb", "Mar", "Apr", "May", \
      "Jun", "Jul", "Aug", "Sept", "Oct", \
      "Nov", "Dec"]
   return months[date.getMonth()] if date instanceof Date
   return months[date]

DateUtils.getWeekDay = (date) ->
   assertArgs(arguments, [Date, Number])
   weekDays = ['Sunday','Monday','Tuesday','Wednesday', \
      'Thursday','Friday','Saturday']
   return weekDays[date.getDay()] if date instanceof Date
   return weekDays[date]

DateUtils.getWeekDayAbbreviation = (date) ->
   assertArgs(arguments, [Date, Number])
   weekDays = ['Sun','Mon','Tues','Wed', \
      'Thu','Fri','Sat']
   return weekDays[date.getDay()] if date instanceof Date
   return weekDays[date]

#endregion Generic Date or Number Section

#region Utility Section
# TODO: This is the only accurate between function here. Update others.
DateUtils.daysBetweenDates = (a, b) ->
   utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate())
   utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate())
   return Math.floor((utc2 - utc1) / (1000 * 60 * 60 * 24))

# Takes MS in UTC
DateUtils.daysBetweenMs = (first, second) ->
   # TODO: Make this based on the JS Date API
   assertArgs(arguments, Number, Number)
   msPerDay = 24 * 60 * 60 * 1000
   dif = (second - first)
   return  Math.round(dif / msPerDay)

###
This function can accept time in the format of a base-10 decimal number (as you might get from rethink),
or a string in the format of "HH:MM:SS" or "HH:MM" (as you might get from postgres).
###
DateUtils.formatTimeVal = (timeVal) ->
   assertArgs(arguments, [Number, String])

   # truthy if you receive rethink data in decimal format
   if String(timeVal).includes(".")
      [hours, minutes] = String(timeVal).split(".")
      minutes = minutes + "0" if String(minutes).length == 1
      minutes = Math.round(minutes * 60 / 100)
      timeVal = "#{hours}:#{minutes}:00"
   # This else-if block is unlikely to ever be true, but still covering this edge case just in-case we ever
   # want to provide custome time strings to this function that aren't from postgres and we omit the seconds
   else if (String(timeVal).split(":").length == 2)
      timeVal = timeVal + ":00"
   else
      timeVal = String(timeVal) + ":00:00"

   # Regardless of where data is coming from or how it was formatted on input, it must match
   # the following timeParse format before being converted into the desired timeFormat
   parseTime = timeParse("%H:%M:%S")
   desiredTimeFormat = timeFormat("%_I:%M %p") # returns format like "1:30 PM"
   return desiredTimeFormat(parseTime(timeVal))

###
This function converts postgres time format of "HH:MM:SS" or "HH:MM" into a float representation.
For example, 1:30 PM would be converted to 13.5
###
DateUtils.timeStringToFloat = (timeVal) ->
   assertArgs(arguments, String)
   [hours, minutes] = String(timeVal).split(":")
   minutes = minutes + "0" if String(minutes).length == 1
   minutes = Math.round(Number(minutes) * 100 / 60)
   return parseFloat("#{hours}.#{minutes}")

DateUtils.getDaysInMonth = (year, monthIndex) ->
   return new Date(year, monthIndex + 1, 0).getDate()

DateUtils.getDurationHours = (startTime, endTime) ->
   assertArgs(arguments, Number, Number)
   if startTime < endTime
      return endTime - startTime
   else if startTime > endTime
      # Overnight
      return (24 - startTime) + endTime
   else
      return 0

DateUtils.getMonthIndex = (monthName) ->
   assertArgs(arguments, String)
   months = ["january", "february", "march", "april", "may", \
      "june", "july", "august", "september", "october", \
      "november", "december"]
   return months.indexOf(monthName.toLowerCase())

DateUtils.getMonthsWorkDaysCount = (year, month) ->
   daysInMonth = 32 - new Date(year, month, 32).getDate()
   workDayCount = 0
   i = 0
   while i < daysInMonth
      day = new Date(year, month, (i + 1)).getDay()
      workDayCount++ unless day == 0 or day == 6
      i++
   return workDayCount

DateUtils.getTime = (date) ->
   assertArgs(arguments, Date)
   hours = date.getHours()
   meridiem = "am"
   if hours == 0
      hours = 12
   else
      if hours >= 12
         meridiem = "pm"
         if hours > 12
            hours = hours - 12
   minutes = date.getMinutes()
   minutes = "0#{date.getMinutes()}" if minutes < 10
   return "#{hours}:#{minutes} #{meridiem}"

DateUtils.getTimeValueForDate = (date) ->
   assertArgs(arguments, Date)
   hours = date.getHours()
   hours = 12 if hours == 0
   minutes = date.getMinutes()
   # 0 needs to do nothing.
   if 0 < minutes <= 15
      hours = hours + 0.25
   else if 15 < minutes <= 30
      hours = hours + 0.5
   else if 30 < minutes <= 45
      hours = hours + 0.75
   else if 45 < minutes <= 60
      hours = hours + 1
   return hours

#endregion Utility Section

DateUtils.DayFormat = DayFormat
DateUtils.MonthFormat = MonthFormat
DateUtils.YearFormat = YearFormat
DateUtils.WeekDayFormat = WeekDayFormat
DateUtils.WorkDayAbbreviations = WorkDayAbbreviations

export DateUtils = DateUtils
