import ko from "knockout"

toMillis = (seconds) => seconds * 1000

Icons = {
   PENDING: "pending",
   INFO: "info",
   SUCCESS: "success",
   WARNING: "warning",
}

export class NotificationManager
   constructor: ->
     @notifications = ko.observableArray()
     
   show:(notification, boundToPage) =>
      active = new ActiveNotification(notification, boundToPage)
      if @notifications().length == 0
         @notifications.push(active)
      @showActiveNotification_(active)

   dismiss: (notification) =>
      for active in @notifications()
         if active.notification == notification
            @dismissActiveNotification_(active)
            @notifications.remove(active)
            break

   dismissAll: () =>
      for active in @notifications()
         @dismissActiveNotification_(active)
      @notifications.removeAll();

   dismissAllUnbound: (page) => 
      for active in @notifications()
         if active.boundToPage != null && active.boundToPage != page
            @dismissActiveNotification_(active)
            @notifications.remove(active)

   showActiveNotification_: (activeNotification) ->
      notification = activeNotification.notification
      activeNotification.isVisible(true)
      if notification.duration and notification.duration > 0
         activeNotification.timerId = setTimeout(() =>
            @dismiss(activeNotification.notification)
         , notification.duration)

   dismissActiveNotification_: (activeNotification) ->
      clearTimeout(activeNotification.timerId)
      activeNotification.isVisible(false)
      activeNotification.notification.onDismiss() if activeNotification.notification.onDismiss

notificationManagerInstance = new NotificationManager()

export class ActiveNotification
   constructor: (notification, boundToPage=null) ->
      @boundToPage = boundToPage
      @notification = notification
      @timerId = null
      @isVisible = ko.observable(false)

   dismiss: () => notificationManagerInstance.dismiss(@notification)
      
export class Notification
   constructor: ({ text, template, duration, onDismiss, icon='info', actions=[] })->
      @template = ko.observable(template)
      @icon = ko.observable(icon)
      @text = ko.observable(text)
      @actions = ko.observableArray(actions)
      @duration = toMillis(duration) if duration?
      @onDismiss = onDismiss
      
export class Action 
   constructor: ({ type = Action.Type.DEFAULT, className = "", onClick, text }) -> 
      @onClick = onClick 
      @text = text
      classNames = []
      classNames.push(type) if type
      classNames.push(className) if className
      @classNames = classNames.join(" ")


Action.Type = {
   DEFAULT: "",
   BLUE: "floating-notification__actions__button--blue",
   RED: "floating-notification__actions__button--red",
}


export notificationManagerInstance = notificationManagerInstance
export Icons = Icons