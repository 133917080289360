import { CancelledError } from "../async/cancelled-error";

export class CancelledStreamError extends CancelledError {}

export interface CancellableStream<T> extends AsyncIterable<T> {
   /**
    * Cancels the stream. A `CancelledStreamError
    */
   cancel(): void;
   isCancelled(): boolean;
}
