import { CommonApiErrorCode } from "@laborchart-modules/lc-core-api/dist/api/";
import type {
   ApiValidationError,
   ApiValidationErrorPayload,
} from "@laborchart-modules/lc-core-api/dist/api/errors";

export interface ApiErrorPayload<T = null> {
   code: string;
   message: string;
   details?: T;
}
export class ApiError<T = null> extends Error {
   readonly code: string;
   readonly details?: T;

   constructor({ code, message, details }: ApiErrorPayload<T>) {
      super(message);
      this.code = code;
      this.details = details;
   }
}

export class UnknownError extends Error {
   constructor(readonly details: unknown) {
      super("Unknown error");
   }
}

export class ValidationError extends ApiError {
   readonly validation: ApiValidationError[];
   constructor({ message, validation }: { message: string; validation: ApiValidationError[] }) {
      super({
         code: CommonApiErrorCode.VALIDATION_ERROR,
         message: message,
      });
      this.validation = validation;
   }
}

/**
 * Used for parsing generic errors that came from Store-based requests, such as those in StreamedUpdate.
 * Immediately passes back anything that is already an instanceof Error, unchanged.
 * Attempts to process objects into ApiErrors, if possible.
 * In all other cases, return an UnknownError.
 */
export function parseError(error: unknown): Error | ApiError | UnknownError {
   if (error instanceof Error) return error;
   if (typeof error == "object" && (error as any).code != null) {
      const payload = {
         message: "Empty",
         ...error,
      } as ApiErrorPayload;
      const visitor = API_ERROR_VISITORS.find((visitor) => visitor.visit(payload));
      return visitor ? visitor.accept(payload) : new ApiError(payload);
   }
   return new UnknownError(error);
}

/** Visitors used to create specific subclasses of ApiError. */
const API_ERROR_VISITORS: Array<{
   visit: (payload: ApiErrorPayload) => boolean;
   accept: (payload: ApiErrorPayload) => ApiError;
}> = [
   {
      visit: (payload) => payload.code == CommonApiErrorCode.VALIDATION_ERROR,
      accept: (payload) => {
         return new ValidationError({
            message: payload.message,
            validation: (payload as ApiValidationErrorPayload).validation || [],
         });
      },
   },
   {
      visit: (payload) => payload.code == CommonApiErrorCode.UNAUTHORIZED,
      accept: (payload) => {
         return new ApiError({
            code: CommonApiErrorCode.UNAUTHORIZED,
            message: payload.message,
         });
      },
   },
];
