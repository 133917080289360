import type { StoreJsonResponse } from "@/stores/common/store.core";
import { Store } from "@/stores/common/store.core";
import LaunchDarklyClient from "@laborchart-modules/launch-darkly-browser";
import type {
   DeleteSessionCurrentResponse,
   DeleteSessionResponse,
   SetSessionCurrentResponse,
} from "@laborchart-modules/lc-core-api/dist/api/sessions/";

export abstract class SessionStore {
   static deleteCurrentSession(): StoreJsonResponse<DeleteSessionCurrentResponse> {
      return Store.requestJson({
         url: "/api/v3/sessions/current",
         method: "DELETE",
      });
   }

   static deleteAllSessions(): StoreJsonResponse<DeleteSessionResponse> {
      return Store.requestJson({
         url: "/api/v3/sessions",
         method: "DELETE",
      });
   }

   static renewSession(
      authingWithProcore: boolean = false,
   ): StoreJsonResponse<SetSessionCurrentResponse> {
      const headers = LaunchDarklyClient.getFlagValue("use-jwt-auth")
         ? { Authorization: `Bearer ${localStorage.getItem("wfpRefreshToken")}` }
         : {};

      return Store.requestJson({
         url: "/api/v3/sessions/current",
         headers,
         maxRetryCount: 0,
         method: "PUT",
         body: {
            authing_with_procore: authingWithProcore,
         },
      });
   }
}
