import { Format as FormatUtils } from "@/lib/utils/format"
import { args as assertArgs, optional, ofType as assertOfType } from "@/vendor/insist"
import ko from "knockout"

export class Model
   constructor: (data) ->
      assertOfType(data.baggage, optional(Object))
      @baggage = ko.observable(data.baggage)

   clone: (modelClass) ->
      assertArgs(arguments, Object)
      results = {}

      processComplexVal = (processingVal) ->
         if processingVal instanceof Array
            valArray = []
            for val in processingVal
               valArray.push(processComplexVal(ko.unwrap(val)))
            return valArray
         else if processingVal instanceof Set
            valSet = new Set()
            for val from processingVal
               valSet.add(processComplexVal(ko.unwrap(val)))
            return valSet
         else if processingVal instanceof Object and !(processingVal instanceof Function)
            valObject = {}
            for key, val of processingVal
               valObject[FormatUtils.prettyCamel(key).replace(/\s/g, "_").toLowerCase()] = processComplexVal(ko.unwrap(val))
            return valObject
         return processingVal

      for key of @
         formattedKey = FormatUtils.prettyCamel(key).replace(/\s/g, "_").toLowerCase()
         resultingVal = processComplexVal(ko.unwrap(@[key]))
         results[formattedKey] = ko.unwrap(resultingVal)

      return new modelClass(results, true)

   allToJson: () ->
      results = {}

      processComplexVal = (processingVal) ->
         if processingVal instanceof Array
            valArray = []
            for val in processingVal
               valArray.push(processComplexVal(ko.unwrap(val)))
            return valArray
         else if processingVal instanceof Object and !(processingVal instanceof Function)
            valObject = {}
            for key, val of processingVal
               valObject[FormatUtils.prettyCamel(key).replace(/\s/g, "_").toLowerCase()] = processComplexVal(ko.unwrap(val))
            return valObject
         return processingVal

      for key of @
         formattedKey = FormatUtils.prettyCamel(key).replace(/\s/g, "_").toLowerCase()
         resultingVal = processComplexVal(ko.unwrap(@[key]))
         results[formattedKey] = ko.unwrap(resultingVal)

      return results

   mapProperties: (modelInstance) ->
      for property in Object.keys(@)
         if ko.isComputed(@[property])
            continue

         if @[property] instanceof Function
            @[property](ko.unwrap(modelInstance[property]))
         else
            @[property] = ko.unwrap(modelInstance[property])
      return @
