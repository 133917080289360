import { Model } from "@/models/model"

export class Attachment extends Model
   constructor: (data, disableTypes) ->
      assertArgs(arguments, optional(Object), optional(Boolean))
      data = {} unless data?
      super(data)
      disableTypes = false unless disableTypes?
      unless disableTypes
         # Required
         assertOfType(data.id, String)
         assertOfType(data.name, String)
         assertOfType(data.created_at, Number)
         assertOfType(data.mimetype, nullable(String))

      ###------------------------------------
         Model ID
      ------------------------------------###
      @id = data.id

      ###------------------------------------
         Knockout Observables
      ------------------------------------###
      @name = ko.observable(data.name)
      @createdAt = ko.observable(data.created_at)
      @mimetype = ko.observable(data.mimetype)

