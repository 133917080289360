import { authManager } from "@/lib/managers/auth-manager"
import { AuthManager2 } from "@/lib/managers/auth-manager-2"
import { requestContext } from "@/stores/common/request-context"
import $ from "jquery"
import ko from "knockout"
import LaunchDarklyBrowser from "@laborchart-modules/launch-darkly-browser"

export class CancellableRequest
   constructor: (jqxhr, options) ->
      assertArgs(arguments, Object, Object)
      @jqxhr = jqxhr
      @options = options
      @isCancelled = false

   cancel: ->
      @jqxhr.abort()
      @isCancelled = true

export class Store
   constructor: () ->
      @vault = {}

   makeRequest: (options, callback) ->
      return Store.makeRequest(options, callback)

   maybeGetVaultData: (key, timestamp, callback) ->
      assertArgs(arguments, String, Number, Function)
      if @vault[key]?
         unless timestamp > @vault[key].timestamp
            return callback(null, @vault[key].data)
         return callback(Store.Error.VAULT_OUTDATED)
      else if @vault[key] == null
         return callback(Store.Error.VAULT_OUTDATED)
      return callback(Store.Error.WRONG_KEY)

   maybeSetVaultData: (key, data, timestamp, callback) =>
      assertArgs(arguments, String, [Number, String, Object, Array], Number, Function)
      if @vault[key] == null
         vaultData = {timestamp: timestamp}
         if data instanceof Array then vaultData.data = ko.observableArray(data) else vaultData.data = ko.observable(data)
         @vault[key] = vaultData
         return callback(null, @vault[key].data())
      else if @vault[key]?
         if timestamp > @vault[key].timestamp
            vaultData = {timestamp: timestamp}
            if data instanceof Array then vaultData.data = ko.observableArray(data) else vaultData.data = ko.observable(data)
            @vault[key] = vaultData
            return callback(null, @vault[key].data())
         else
            # Passed in data was outdated so return latest data in vault.
            return callback(null, @vault[key].data())
      else
         callback(Store.Error.WRONG_KEY)

   emptyVault: (key) ->
      assertArgs(arguments, String)
      @vault[key] = null

   @makeRequest: (options, callback) ->
      assertArgs(arguments, Object, Function)
      headers = if LaunchDarklyBrowser.getFlagValue('use-jwt-auth')
         { 'Authorization': "Bearer #{localStorage.getItem('wfpAccessToken')}" }
      else
         {}

      jqxhr = $.ajax
         url: requestContext.baseUrl + options.url
         method: options.method || "GET"
         contentType: "application/json"
         headers: headers
         data: JSON.stringify(options.data) || null
         cache: false
         xhrFields: {
            withCredentials: true
         }
         success: (data) ->
            return if request.isCancelled
            callback(null, data)
         error: (error) ->
            return if request.isCancelled
            if error.status == 401
               # Attempt to start a new session and restart the request.
               authManager.attemptToRenewSession =>
                  # Prevent infinite trying.
                  if options.blockRetry
                     return callback(error)
                  else
                     options['blockRetry'] = true
                     Store.makeRequest(options, callback)
            else if error.status == 403
               authManager.logout (err) ->
                  console.log "error logging out: ", err if err
                  # TODO: Would be nice to send them back where they were after login.
                  AuthManager2.navigateToSignIn()
            else
               if error.responseJSON?
                  try
                     parsedError = JSON.parse(error.responseJSON.message)
                     return callback(parsedError)
                  catch
                     return callback(error)
               callback(error)

      request = new CancellableRequest(jqxhr, options)
      return request


Store.Error = {
   VAULT_OUTDATED: "Data in vault is not up to date"
   WRONG_KEY: "Provided key did not match any vault keys"
   OUTDATED: "The provided data is older than vault data"
}
