import { Model } from "@/models/model"
import { Attachment } from "@/models/attachment"

export class Tag extends Model
   constructor: (data, disableTypes) ->
      assertArgs(arguments, optional(Object), optional(Boolean))
      data = {} unless data?
      super(data)
      disableTypes = false unless disableTypes?
      unless disableTypes
         # Required
         assertOfType(data.id, String)
         assertOfType(data.tag_id, optional(String))
         assertOfType(data.name, String)
         assertOfType(data.abbreviation, String)
         assertOfType(data.color, String)
         assertOfType(data.effective_date, optional(Number))
         assertOfType(data.require_expr_date, Boolean)
         if data.require_expr_date
            assertOfType(data.expr_days_warning, optional(Number))
            assertOfType(data.expr_date, optional(Number))
         assertOfType(data.attachments, optional(arrayOf(Object)))
         assertOfType(data.globally_accessible, optional(Boolean))
         assertOfType(data.group_ids, optional(arrayOf(String)))
         assertOfType(data.categories, optional(arrayOf(String)))

      ###------------------------------------
         Model ID
      ------------------------------------###
      @id = data.id

      ###------------------------------------
         Knockout Observables
      ------------------------------------###
      @tagId = ko.observable(data.tag_id)
      @name = ko.observable(data.name)
      @abbreviation = ko.observable(data.abbreviation)
      @color = ko.observable(data.color)
      @effectiveDate = ko.observable(data.effective_date)
      @requireExprDate = ko.observable(data.require_expr_date)
      @exprDaysWarning = ko.observable(data.expr_days_warning)
      @effectiveDate = ko.observable(data.effective_date)
      @exprDate = ko.observable(data.expr_date)
      @attachments = ko.observableArray()
      @categories = ko.observableArray(data.categories)

      @globallyAccessible = ko.observable(data.globally_accessible)
      @groupIds = ko.observableArray(data.group_ids)

      if data.attachments instanceof Array
         @attachments data.attachments.map (item) ->
            return new Attachment(item, disableTypes)

