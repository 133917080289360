import { Model } from "@/models/model"
import ko from "knockout"

export class Position extends Model
   constructor: (data, disableTypes) ->
      assertArgs(arguments, optional(Object), optional(Boolean))
      data = {} unless data?
      super(data)
      disableTypes = false unless disableTypes?
      unless disableTypes
         # Required
         assertOfType(data.id, String)
         assertOfType(data.name, String)
         assertOfType(data.color, String)
         assertOfType(data.globally_accessible, Boolean)
         assertOfType(data.group_ids, arrayOf(String))
         assertOfType(data.type, nullable(String))
         assertOfType(data.sequence, nullable(Number))
         assertOfType(data.hourly_rate, optional(Number))

      ###------------------------------------
         Model ID
      ------------------------------------###
      @id = data.id

      ###------------------------------------
         Knockout Observables
      ------------------------------------###
      @name = ko.observable(data.name)
      @color = ko.observable(data.color)
      @globallyAccessible = ko.observable(data.globally_accessible)
      @type = ko.observable(data.type)
      @sequence = ko.observable(data.sequence)
      @hourlyRate = ko.observable(data.hourly_rate)

      ###------------------------------------
         Knockout Observable Arrays
      ------------------------------------###
      @groupIds = ko.observableArray(data.group_ids)

Position.Type = {
   HOURLY: "hourly"
   SALARY: "salary"
   ALL: "all"
}
