import { Model } from "@/models/model"
import ko from "knockout"

export class ValueSet extends Model
   constructor: (data, disableTypes) ->
      assertArgs(arguments, optional(Object), optional(Boolean))
      data = {} unless data?
      super(data)
      disableTypes = false unless disableTypes?
      unless disableTypes
         # Required
         assertOfType(data.id, optional(String))

      ###------------------------------------
         Model ID & Meta
      ------------------------------------###
      @id = data.id

      ###------------------------------------
         Knockout Observables
      ------------------------------------###
      @name = ko.observable(data.name)
      @value = ko.observable(data.value)
      @color = ko.observable(data.color)
      @selected = ko.observable(data.selected)

