import { Model } from "@/models/model"

### Framework Includes ###
import ko from "knockout"

export class NotificationProfile extends Model
   constructor: (data, disableTypes) ->
      assertArgs(arguments, optional(Object), optional(Boolean))
      data = {} unless data?
      super(data)
      disableTypes = false unless disableTypes?
      unless disableTypes
         # Required
         assertOfType(data.id, String)
         assertOfType(data.name, String)
         assertOfType(data.person_existence_changed, Boolean)
         assertOfType(data.person_groups_changed, Boolean)
         assertOfType(data.person_status_changed, Boolean)
         assertOfType(data.person_tag_warning, Boolean)
         assertOfType(data.person_tag_expiring, Boolean)
         assertOfType(data.project_existence_changed, Boolean)
         assertOfType(data.project_groups_changed, Boolean)
         assertOfType(data.project_status_changed, Boolean)
         assertOfType(data.assigned_as_project_role, Boolean)
         assertOfType(data.assignment_created, String)
         assertOfType(data.assignment_edited, String)
         assertOfType(data.assignment_deleted, String)
         assertOfType(data.assignment_starting, String)
         assertOfType(data.assignment_ending, String)
         assertOfType(data.request_created, String)
         assertOfType(data.request_edited, String)
         assertOfType(data.request_filled, String)
         assertOfType(data.request_deleted, String)
         assertOfType(data.request_starting, String)
         assertOfType(data.request_ending, String)

      ###------------------------------------
         Model ID & Meta
      ------------------------------------###
      @id = data.id

      ###------------------------------------
         Knockout Observables
      ------------------------------------###
      @companyId = ko.observable(data.company_id)
      @name = ko.observable(data.name)

      # People
      @personExistenceChanged = ko.observable(data.person_existence_changed)
      @personGroupsChanged = ko.observable(data.person_groups_changed)
      @personStatusChanged = ko.observable(data.person_status_changed)
      @personTagWarning = ko.observable(data.person_tag_warning)
      @personTagExpiring = ko.observable(data.person_tag_expiring)

      # Projects
      @projectExistenceChanged = ko.observable(data.project_existence_changed)
      @projectGroupsChanged = ko.observable(data.project_groups_changed)
      @projectStatusChanged = ko.observable(data.project_status_changed)
      @assignedAsProjectRole = ko.observable(data.assigned_as_project_role)

      # Assignments
      @assignmentCreated = ko.observable(data.assignment_created)
      @assignmentEdited = ko.observable(data.assignment_edited)
      @assignmentDeleted = ko.observable(data.assignment_deleted)
      @assignmentStarting = ko.observable(data.assignment_starting)
      @assignmentEnding = ko.observable(data.assignment_ending)

      # Requests
      @requestCreated = ko.observable(data.request_created)
      @requestEdited = ko.observable(data.request_edited)
      @requestFilled = ko.observable(data.request_filled)
      @requestDeleted = ko.observable(data.request_deleted)
      @requestStarting = ko.observable(data.request_starting)
      @requestEnding = ko.observable(data.request_ending)

      @enabledCount = ko.observable(0)
      actionCount = 0
      for key, val of @
         rawVal = ko.unwrap(val)
         continue unless typeof rawVal == "boolean" or typeof rawVal == "string"
         continue if key == "companyId" or key == "id" or key == "name"
         continue if rawVal == "none" or !rawVal
         actionCount++

      @enabledCount(actionCount)

