import { JsonStream } from "@/lib/streams/json-stream";
import { param } from "jquery";
import type { CancellableStream } from "@/lib/streams/cancellable-stream";
import { CancelledError } from "@/lib/async/cancelled-error";
import { parseError } from "./store-errors";
import type { Notification } from "@/lib/managers/notification-manager";
import { notificationManagerInstance } from "@/lib/managers/notification-manager";
import { SessionStore } from "../session-store.core";
import { requestContext } from "@/stores/common/request-context";
import { AuthManager2 } from "@/lib/managers/auth-manager-2";
import type { NotifiableError } from "@bugsnag/js";
import Bugsnag from "@bugsnag/js";
import { BUGSNAG_META_TAB, buildUserData } from "@/lib/utils/bugsnag-content-helper";
import { authManager } from "@/lib/managers/auth-manager";
import LaunchDarklyClient from "@laborchart-modules/launch-darkly-browser";

export class CancelledRequestError extends CancelledError {}

export type StoreRequestHeader = {
   Authorization?: string;
   "Content-Type"?: string;
};

export type StoreRequestErrorHandlingOptions = {
   errorNotification?: Notification | null;
};

export type StoreRequestOptions = StoreRequestErrorHandlingOptions & {
   url: string;
   body?: any;
   credentials?: RequestCredentials;
   headers?: StoreRequestHeader;
   maxRetryCount?: number;
   method?: string;
   query?: any[] | { [key: string]: any };
   signal?: AbortSignal;
};

export type StoreJsonResponse<T> = {
   cancel(): void;
   payload: Promise<T>;
};

export type StoreStreamResponse<T> = {
   cancel(): void;
   stream: Promise<CancellableStream<T>>;
};

export abstract class Store {
   static requestJson<T>(options: StoreRequestOptions): StoreJsonResponse<T> {
      const controller = new AbortController();
      const { signal } = controller;

      return {
         cancel() {
            controller.abort();
         },
         payload: this.requestInternal({
            ...options,
            signal,
            headers: {
               ...options.headers,
               ...(options.body instanceof FormData ? {} : { "Content-Type": "application/json" }),
            },
         }).then((response) => {
            return response.json();
         }),
      };
   }

   static requestStream<T>(options: StoreRequestOptions): StoreStreamResponse<T> {
      const controller = new AbortController();
      const { signal } = controller;

      return {
         cancel() {
            if (!controller.signal.aborted) controller.abort();
         },
         stream: this.requestInternal({
            ...options,
            signal,
            headers: {
               ...options.headers,
               "Content-Type": "application/json",
            },
         }).then((response) => {
            if (!response.body && !(response as any)._bodyText) {
               return new JsonStream(
                  new ReadableStream({
                     start(controller) {
                        controller.close();
                     },
                  }),
                  () => {},
               );
            }

            /**
             * Bandaid fix for the embedded app.
             *
             * The embedded app currently is not returning a body, however _bodyText is being returned.
             * This encodes the _bodyText back into bytes and enqueues them into the stream.
             */
            if (!response.body) {
               const bodyText = (response as any)._bodyText;
               const encoder = new TextEncoder();
               return new JsonStream(
                  new ReadableStream({
                     start(controller) {
                        const encodedBody = encoder.encode(bodyText);
                        controller.enqueue(encodedBody);
                        controller.close();
                     },
                  }),
                  () => {
                     if (!controller.signal.aborted) controller.abort();
                  },
               );
            }

            return new JsonStream<T>(response.body, () => {
               if (!controller.signal.aborted) controller.abort();
            });
         }),
      };
   }

   static async request(options: StoreRequestOptions): Promise<Response> {
      return this.requestInternal(options);
   }

   private static async requestInternal(
      options: StoreRequestOptions,
      retryCount: number = 0,
   ): Promise<Response> {
      const {
         url,
         method = "GET",
         credentials = "include",
         headers,
         maxRetryCount = 1,
         body,
         signal,
      } = options;

      const authorizationHeader = LaunchDarklyClient.getFlagValue("use-jwt-auth")
         ? { Authorization: `Bearer ${localStorage.getItem("wfpRefreshToken")}` }
         : {};

      const finalUrl =
         requestContext.baseUrl +
         (options.query ? this.appendToQuerystring(url, options.query) : url);

      // Make request. Transform cancelled errors into a `RequestCancelledError`.
      let response: Response;

      try {
         response = await window.fetch(finalUrl, {
            signal,
            method: method,
            credentials,
            headers: { ...authorizationHeader, ...headers } as HeadersInit | undefined,
            body: body instanceof FormData ? body : JSON.stringify(body),
         });
      } catch (error: any) {
         if (error?.name == "AbortError") {
            throw new CancelledRequestError();
         }
         Bugsnag.notify(error as NotifiableError, (event) => {
            event.context = `store-core_requestInternal__${options.url}`;
            event.addMetadata(
               BUGSNAG_META_TAB.USER_DATA,
               buildUserData(authManager.authedUser()!, authManager.activePermission),
            );
            event.addMetadata("store request options", options);
         });
         if (options.errorNotification) {
            notificationManagerInstance.show(options.errorNotification);
         }
         throw error;
      }

      // Process response.
      switch (response.status) {
         case 200:
            return response;
         case 401:
            if (retryCount < maxRetryCount) {
               try {
                  await SessionStore.renewSession().payload;
               } catch (error) {
                  AuthManager2.navigateToSignIn();
                  throw error;
               }

               // Delay for half a second to allow the browser time to update the cookie jar.
               // Without this delay, the next request will not contain the updated cookie.
               await new Promise((resolve) => setTimeout(resolve, 250));

               return this.requestInternal(options, retryCount + 1);
            }
         // Intentionally fallthrough.
         default:
            /* eslint-disable-next-line no-case-declarations */
            const error = parseError(await response.json());
            if (response.status !== 401) {
               Bugsnag.notify(error as NotifiableError, (event) => {
                  event.context = `store-core_requestInternal__${options.url}`;
                  event.addMetadata(
                     BUGSNAG_META_TAB.USER_DATA,
                     buildUserData(authManager.authedUser()!, authManager.activePermission),
                  );
                  event.addMetadata("store request options", options);
               });
            }
            if (options.errorNotification && response.status !== 401) {
               notificationManagerInstance.show(options.errorNotification);
            }
            throw error;
      }
   }

   private static appendToQuerystring(url: string, query: any[] | { [key: string]: any }): string {
      const querystring = (() => {
         if (Array.isArray(query)) return param(query);

         // Filter out undefined keys before serializing the querystring.
         const copy = { ...query };
         for (const key in copy) {
            if (copy[key] === undefined) {
               delete copy[key];
            }
         }
         return param(copy);
      })();
      return `${url}${url.includes("?") ? "" : "?"}${querystring}`;
   }

   static getFullRequestUrl({ path, query }: { path: string; query?: any }) {
      return requestContext.baseUrl + (query ? this.appendToQuerystring(path, query) : path);
   }
}
