import { router } from "@/lib/router"
import { Format as FormatUtils } from "@/lib/utils/format"
import { Url as UrlUtils } from "@/lib/utils/url"
import ko from "knockout"
import $ from "jquery"
import Bugsnag from "@bugsnag/js"
import { BUGSNAG_META_TAB, buildUserData } from "@/lib/utils/bugsnag-content-helper";
import LaunchDarklyBrowser from "@laborchart-modules/launch-darkly-browser"

### Stores ###
import { defaultStore } from "@/stores/default-store"
import { requestContext } from "@/stores/common/request-context";

### Models ###
import { Person } from "@/models/person"

import { SessionStore } from "@/stores/session-store.core"

import { AuthManager2 } from "@/lib/managers/auth-manager-2"


export class AuthManager
   constructor: () ->
      @Bugsnag = Bugsnag
      @authingWithProcore = requestContext.usingProcoreHostApp
      @authedUser = ko.observable(null)
      @companyId = ko.computed =>
         return @authedUser()?.companyId()
      @selectedGroupId = ko.observable()
      @activePermission = ko.computed =>
         return @authedUser()?.permissionLevel()
      @authedUserId = ko.pureComputed =>
         return if @authedUser()? then @authedUser().id else null

      @isAdmin = ko.computed =>
         return false unless @authedUser()?.permissionLevel()?
         return @authedUser()?.permissionLevel().isAdmin()

      @usingTypedGroups = ko.pureComputed =>
         return if @authedUser()?.baggage()? then @authedUser()?.baggage().using_typed_groups

      @peopleSensitiveFields = ko.observableArray()
      @projectsSensitiveFields = ko.observableArray()

      @companyModules = ko.observable(null)

      # This may be more than the user has access to intentionally as it supports other data.
      # TODO: This need to be reset on fanout
      @companyGroupNames = ko.observable(null)

      @isSupportUser = ko.computed =>
         return false unless @authedUser()?.baggage()
         return @authedUser().baggage().is_support

   setAuthedUser: (userData) =>
      person = if userData instanceof Person then userData else new Person(userData, true) 
      try
         # Check the URL and session storage for the helix header
         # If the query param is passed and header is not set,
         # Persist the session storage value to maintain helix experience as the user navigates around
         # the entire procore platform. The value will be cleared when the tab is closed.
         urlParams = new URLSearchParams(window.location.search);
         helix_header = sessionStorage.getItem("helix-header")
         helix_param = urlParams.get("helix")
         helix_enabled = helix_header == "true" or helix_param == "true"
         if helix_enabled and !helix_header
            sessionStorage.setItem("helix-header", "true")
         await LaunchDarklyBrowser.identify({
            kind: 'multi',
            user: { kind: 'user', key: person.email(), helix: helix_enabled},
            email: { kind: 'email', key: person.email() },
            company_id: { kind: 'company_id', key: person.companyId() }
         })
      catch e
         console.log("Error calling LD and setting context", e)
   
      @authedUser(person)
      if @authedUser()?.baggage()?.date_format
         defaultStore.setDateFormat(@authedUser().baggage().date_format)
      @companyModules({
         customFields: person.baggage().company_modules.custom_fields_module
         qrCodes: person.baggage().company_modules.qr_code_module
         peopleQrCodes: person.baggage().company_modules.people_qr_codes
         projectsQrCodes: person.baggage().company_modules.projects_qr_codes
         tagCategories: person.baggage().company_modules.using_tag_categories
      })

      if person.baggage().sensitive_fields
         @peopleSensitiveFields(person.baggage().sensitive_fields.people_sensitive_fields)
         @projectsSensitiveFields(person.baggage().sensitive_fields.projects_sensitive_fields)

      @Bugsnag.setUser(person.id, person.email(), "#{person.firstName()} #{person.lastName()}")


      if window.config.LC_TIER == "prod" || window.config.LC_TIER == "training" || window.config.LC_TIER == "staging"
         pendo.initialize({
            visitor: {
               id: person.id,
               email: person.email(),
               full_name: person.fullName()
            },
            account: {
               id: person.companyId(),
               name: person.baggage().company_name,
               tier: window.config.LC_TIER
            }
         })

   forceSessionRenew: =>
      @attemptToRenewSession (err, data) =>
         return AuthManager2.navigateToSignIn(true) if err
         user = AuthManager2.getPersonModel(data.user)
         @setAuthedUser(user).then () ->
            return

   protect: (req, next) =>
      groupId = req.params.groupId or @selectedGroupId() or null
      getNewSession = =>
         @attemptToRenewSession (err, data) =>
            return AuthManager2.navigateToSignIn(true) if err
            user = AuthManager2.getPersonModel(data.user)
            @setAuthedUser(user).then () =>
               if @companyGroupNames()?
                  return @updateGroupId(groupId, next)
               else
                  @updateGroupId groupId, () =>
                     return @getCompanyEntityOptions_(next)

      @checkSession_ (err, activeSession) =>
         return getNewSession() if err
         if activeSession and groupId? and groupId != @selectedGroupId() and @authedUser()?
            if @companyGroupNames()?
               return @updateGroupId(groupId, next)
            else
               return @updateGroupId groupId, () =>
                  return @getCompanyEntityOptions_(next)
         else if activeSession and groupId? and groupId == @selectedGroupId() and @authedUser()?
            if @companyGroupNames()?
               return next()
            else
               return @getCompanyEntityOptions_(next)
         else if activeSession? and @authedUser()?
            if @companyGroupNames()?
               # No need to set default becuase all data is pulled down
               # they are just hitting a page that doesn't require a group ID.
               # nulling the group ID out here will cause an issue, resetting the
               # group back to default one instead of last selected.
               return next()
            else
               return @getCompanyEntityOptions_(next)

         return getNewSession()


   getCompanyEntityOptions_: (next) ->
      headers = if LaunchDarklyBrowser.getFlagValue('use-jwt-auth')
         { 'Authorization': "Bearer #{localStorage.getItem('wfpAccessToken')}" }
      else
         {}
      params = UrlUtils.serializeParams({entities: ['groups']})

      $.ajax
         url: requestContext.baseUrl + "/api/v3/company/entity-options?#{params}"
         method: "GET"
         headers: headers
         contentType: "application/json"
         crossDomain: true
         xhrFields: {
            withCredentials: true
         }
         success: (response) =>
            groupNames = {}
            for option in response.data?.group_options
               groupNames[option.value] = option.name
            @companyGroupNames(groupNames)

            next()
         error: () =>
            @companyGroupNames(null)
            next(AuthManager.Error.GROUP_OPTIONS_FAILED)

   checkAuthAction: (action) ->
      return false unless @activePermission()?
      return true if @isAdmin()

      permissionAction = @activePermission()[action]
      unless permissionAction?
         Bugsnag.notify("Unrecognized Permission Action: #{action}", (event) =>
            event['context'] = "auth-manager_checkAuthAction"
            event.addMetadata(BUGSNAG_META_TAB.USER_DATA, buildUserData(authManager.authedUser(), authManager.activePermission))
         )
         return false

      return permissionAction()

   checkActions: (actions, next) =>
      assertArgs(arguments, arrayOf(String), Function)
      # TODO: Should we just always check even if admin? Any security risk?
      return next() if @isAdmin()
      for action in actions
         return router.navigate(null, "/access-denied") unless @activePermission()[action]()
      next()

   checkOrActions: (actions, next) =>
      assertArgs(arguments, arrayOf(String), Function)
      return next() if @isAdmin()
      match = false
      for action in actions
         if @activePermission()[action]()
            match = true
            break
      unless match
         return router.navigate(null, "/access-denied")
      next()

   updateGroupId: (groupId, callback) =>
      assertArgs(arguments, nullable(String), Function)
      if groupId? and (@isAdmin() or
      @authedUser()?.groupIds().indexOf(groupId) != -1 or groupId == "my-groups")
         @selectedGroupId(groupId)
      else if groupId? and @authedUser()?.groupIds().indexOf(groupId) == -1
         id = FormatUtils.keyableSort(@authedUser().baggage().group_options, 'name')[0].id
         if id?
            @selectedGroupId(id)
            router.updateUrlPathParam("groups", id)
         else
            return @logout ->
               AuthManager2.navigateToSignIn()

      else if @authedUser()? and !groupId?
         if @authedUser().preferences().defaultGroupId()?
            @selectedGroupId(@authedUser().preferences().defaultGroupId())
         else
            id = FormatUtils.keyableSort(@authedUser().baggage().group_options, 'name')[0].id
            @selectedGroupId(id)
      else
         @selectedGroupId(null)

      callback(null)

   checkSession_: (callback) =>
      headers = if LaunchDarklyBrowser.getFlagValue('use-jwt-auth')
         { 'Authorization': "Bearer #{localStorage.getItem('wfpAccessToken')}" }
      else
         {}
      $.ajax
         url: requestContext.baseUrl + "/api/v3/sessions/current"
         method: "GET"
         headers: headers
         contentType: "application/json"
         crossDomain: true
         xhrFields: {
            withCredentials: true
         }
         success: (data) =>
            return callback(AuthManager.Error.INVALID_CREDENTIALS) if !data.data?
            user = data.data?.authenticated_person
            @setAuthedUser(AuthManager2.getPersonModel(user)).then () =>
               callback(null, user?)
         error: (data) =>
            callback(data)

   attemptToRenewSession: (callback) =>
      try
         data = await AuthManager2.attemptToRenewSession({
            authingWithProcore: @authingWithProcore,
         })
         callback(null, data)
      catch err
         callback(err)

   attemptProcoreLogin: (procoreCompanyId, procoreAccessToken, callback) =>
      assertArgs(arguments, String, String, Function)

      data = {
         pc_company_id: procoreCompanyId
         access_token: procoreAccessToken
      }

      $.ajax
         url: requestContext.baseUrl + "/api/v3/sessions/procore/current"
         method: "POST"
         contentType: "application/json"
         data: JSON.stringify(data)
         crossDomain: true,
         xhrFields: {
            withCredentials: true
         }
         success: (data, _responseText, response) =>
            return callback(response) unless data.data?

            @authingWithProcore = true

            { session: { authenticated_person }, wfp_access_token } = data.data

            # Override user permissions that we want to disable for Procore experience.
            authenticated_person.permission_level.view_people = false
            user = AuthManager2.getPersonModel(authenticated_person)

            @setAuthedUser(user)
            @updateGroupId "my-groups", =>
               @getCompanyEntityOptions_(callback)

            return wfp_access_token

         error: (data) =>
            callback(data)

   handleAccountSetup: (data, callback) =>
      $.ajax
         url: "/api/companies"
         method: "POST"
         contentType: "application/json"
         data: JSON.stringify(data)
         success: (data) =>
            @setAuthedUser(data.user).then () =>
               @selectedGroupId(data.user.group_ids[0])
               if data.user.baggage.sensitive_fields
                  @peopleSensitiveFields(data.user.baggage.sensitive_fields.people_sensitive_fields)
                  @projectsSensitiveFields(data.user.baggage.sensitive_fields.projects_sensitive_fields)
               callback(null)
         error: (data) =>
            callback(data)

   logout: (callback) ->
      assertArgs(arguments, optional(Function))
      try
         await SessionStore.deleteCurrentSession().payload
         @authedUser(null)
         @companyModules(null)
         @selectedGroupId(null)
         return callback(null, true) if callback
      catch e
         return callback(e)

   logoutAllDevices: (callback) ->
      assertArgs(arguments, optional(Function))
      try
         await SessionStore.deleteAllSessions().payload
         @authedUser(null)
         @companyModules(null)
         @selectedGroupId(null)
         return callback(null, true) if callback
      catch e
         return callback(e)

   getContextAccessibleGroupIds: () =>
      if @usingTypedGroups()
         return new Set(@authedUser()?.accessGroupIds())
      else
         return new Set(@authedUser()?.groupIds())

   isAccessibleGroupId: (groupId) =>
      return @isAdmin() == true || @getContextAccessibleGroupIds().has(groupId)

   isVisibleGroupId: (groupId) =>
      selectedGroup = @selectedGroupId()
      if selectedGroup != null && selectedGroup != "my-groups"
         return selectedGroup == groupId
      else
         return @isAccessibleGroupId(groupId)

   # groupIds is assumed to be a Set<string>.
   getGroupNames: (groupIds) =>
      if groupIds
         groupNames = Object.entries(authManager.companyGroupNames()).filter(([id]) => groupIds.has(id))
      else
         groupNames = Object.entries(authManager.companyGroupNames())

      return groupNames
         # eslint-disable-next-line no-unused-vars
         .map(([_, name]) => name)

   # groupIds is assumed to be a Set<string>.
   getSortedGroupNames: (groupIds) =>
      @getGroupNames(groupIds).sort((a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()))

   getVisibilitySortedGroupItems: (list, groupIdSelector) =>
      allGroupNames = @companyGroupNames()
      accessibleGroupIds = @getContextAccessibleGroupIds()
      validGroupItems = list
         .filter((item) => allGroupNames[groupIdSelector(item)]);
      accessibleValidGroupItems = validGroupItems
         .filter((item) => accessibleGroupIds.has(groupIdSelector(item)))
         .sort((left, right) =>
            allGroupNames[groupIdSelector(left)].toLocaleLowerCase()
            .localeCompare(allGroupNames[groupIdSelector(right)].toLocaleLowerCase()));
      nonAccessibleValidGroupItems = validGroupItems
         .filter((item) => !accessibleGroupIds.has(groupIdSelector(item)))
         .sort((left, right) =>
            allGroupNames[groupIdSelector(left)].toLocaleLowerCase()
            .localeCompare(allGroupNames[groupIdSelector(right)].toLocaleLowerCase()));
      return [
         ...accessibleValidGroupItems,
         ...nonAccessibleValidGroupItems
      ];

   getVisibilitySortedGroupNames: (groupIds) =>
      allGroupNames = @companyGroupNames()
      groupIdsToSort = groupIds || Object.keys(@companyGroupNames())
      @getVisibilitySortedGroupItems([...groupIdsToSort], (id) => id).map((id) => allGroupNames[id])

AuthManager.Error = {
   INVALID_CREDENTIALS: "invalid credentials"
   RESTRICTED_GROUP_ACCESS: "User does not have access to group"
   GROUP_OPTIONS_FAILED: "Failed to get the group options"
}

AuthManager.PermissionLevels = [
   Person.Permission.RESTRICTED_VIEWER
   Person.Permission.VIEWER
   Person.Permission.RESTRICTED_USER
   Person.Permission.USER
   Person.Permission.RESTRICTED_MANAGER
   Person.Permission.MANAGER
   Person.Permission.ADMIN
]

export authManager = new AuthManager()
