import LaunchDarklyClient from "@laborchart-modules/launch-darkly-browser";

export type WindowType = {
   config?: {
      LC_PROCORE_AUTH_URL: string;
      LC_PROCORE_REDIRECT_URI: string;
      LC_WFP_OAUTH_CLIENT_ID: string;
      LC_WFP_OAUTH_REDIRECT_URI: string;
   };
} & Window &
   typeof globalThis;

export abstract class ProcoreStore {
   static loginWithHostApp(): void {
      const config = (window as WindowType).config;
      const authUrl: string = config?.LC_PROCORE_AUTH_URL || "";
      const clientID: string = config?.LC_WFP_OAUTH_CLIENT_ID || "";
      const state: string = window.location.href;
      let redirectURI: string = config?.LC_WFP_OAUTH_REDIRECT_URI || "";

      if (LaunchDarklyClient.getFlagValue("use-jwt-auth") === true) {
         redirectURI =
            location.origin + "/webclients/host/companies/tools/workforce-planning/oauth/callback";
      }

      const url = `${authUrl}?client_id=${clientID}&response_type=token&redirect_uri=${redirectURI}&state=${state}`;

      window.location.replace(url);
   }
}
